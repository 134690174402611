import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_FLOORS_PAGINATION_QUERY } from "@/graphql/queries/configuration/floor/get-floors-pagination.query";

const floor = {
  actions: {
    async getFloorsQuery({ commit }, { skip, take, orderBy, query }) {
      return await handleGqlResponseBody(({ query: queryMethod }) => {
        return queryMethod({
          query: GET_FLOORS_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default floor;
