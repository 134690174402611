<template>
  <v-main>
    <TopBarComponent :max-width="1200">
      <slot />
    </TopBarComponent>
  </v-main>
</template>

<script>
import TopBarComponent from "@/components/TopBarComponent";
import { mapGetters } from "vuex";

const SYSTEM_CONFIG_UPDATE_INTERVAL = 60000;
export default {
  name: "MainLayout",
  components: { TopBarComponent },
  computed: {
    ...mapGetters({
      isSystemReady: "isSystemReady",
    }),
  },
  data() {
    return {
      systemConfigUpdateInterval: null,
    };
  },
  async mounted() {
    console.log("MAIN layout mounted");
    await this.$store.dispatch("loadSystemConfigs");
    this.systemConfigUpdateInterval = setInterval(async () => {
      await this.$store.dispatch("loadSystemConfigs");
    }, SYSTEM_CONFIG_UPDATE_INTERVAL);
  },
  beforeDestroy() {
    if (this.systemConfigUpdateInterval) clearInterval(this.timerInterval);
  },
};
</script>
