import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_DISHES_PAGINATION_QUERY } from "@/graphql/queries/configuration/dish/get-dishes-pagination.query";

const dish = {
  actions: {
    async getDishesQuery({ commit }, { skip, take, orderBy, query }) {
      return await handleGqlResponseBody(({ query: queryMethod }) => {
        return queryMethod({
          query: GET_DISHES_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default dish;
