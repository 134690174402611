import gql from "graphql-tag";

export const GET_EXCHANGE_RATE_GROUPS_QUERY = gql`
  query GetExchangeRateGroups(
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    getExchangeRateGroups(
      data: { orderBy: $orderBy, pagination: { skip: $skip, take: $take } }
    ) {
      items {
        id
        createdAt
        fromDate
        defaultCurrencyTitle
        defaultCurrency {
          title
          symbol
          isMain
        }
        rates {
          id
          currencyTitle
          currency {
            title
            symbol
            isMain
          }
          value
        }
      }
      total
      hasMore
    }
  }
`;
