import 'regenerator-runtime/runtime'
import html2canvas from 'html2canvas';

const VueHtml2Canvas = {
    install (Vue, options) {
        Vue.mixin({
            methods: {
                async $html2canvas (el, options = {}) {
                    return html2canvas(el, options);
                }
            }
        });
    }
};

export default VueHtml2Canvas;
