import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_ACTUAL_SHIFT_USER_GROUPS_QUERY } from "@/graphql/queries/user-sheet/get-actual-shift-user-groups.query";
import {
  GET_USER_SHIFT_HISTORY_COMPUTED_PAGINATION_QUERY,
} from "@/graphql/queries/configuration/user-sheet/get-user-shift-history-computed-pagination.query";
import {
  GET_USER_SHIFT_HISTORY_PAGINATION_QUERY
} from "@/graphql/queries/configuration/user-sheet/get-user-shift-history-pagination.query";
import {
  GET_USER_SHIFT_PAGINATION_QUERY
} from "@/graphql/queries/configuration/user-sheet/get-user-shift-pagination.query";
import {
  GET_USER_SHEET_DAYS_CONFIG_PAGINATED_QUERY
} from "@/graphql/queries/configuration/user-sheet/get-user-sheet-days-config-paginated.query";

const userSheet = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getActualShiftUserGroupsQuery() {
      return handleGqlResponseBody(({ query }) => {
        return query({
          query: GET_ACTUAL_SHIFT_USER_GROUPS_QUERY,
          fetchPolicy: "no-cache",
        });
      });
    },
    getUserSheetDaysConfigPaginationQuery({ commit }, { skip, take, orderBy }) {
      return handleGqlResponseBody(({ query }) => {
        return query({
          query: GET_USER_SHEET_DAYS_CONFIG_PAGINATED_QUERY,
          variables: {
            skip,
            take,
            orderBy
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    getUserShiftPaginationQuery({ commit }, { skip, take, orderBy }) {
      return handleGqlResponseBody(({ query }) => {
        return query({
          query: GET_USER_SHIFT_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    getUserShiftHistoryComputedPaginationQuery({ commit }, { skip, take, orderBy, search }) {
      return handleGqlResponseBody(({ query }) => {
        return query({
          query: GET_USER_SHIFT_HISTORY_COMPUTED_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query: search,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    getUserShiftHistoryPaginationQuery({ commit }, { skip, take, orderBy, userGroupShiftId }) {
      return handleGqlResponseBody(({ query }) => {
        return query({
          query: GET_USER_SHIFT_HISTORY_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            userGroupShiftId
          },
          fetchPolicy: "no-cache",
        });
      });
    }
  },
};

export default userSheet;
