<template>
  <v-select
    class="d-inline-block"
    style="width: 80px"
    v-model="currentLanguage"
    :items="languages"
    item-text="title"
    item-value="id"
    outlined
    dense
    hide-details="auto"
  />
</template>

<script>
import {
  getObjectFromLocalStorage,
  setObjectToLocalStorage,
} from "@/utils/local-storage.util";

export default {
	data (){
		return {
			currentLanguage: getObjectFromLocalStorage("lang")
		}
	},
  computed: {
    languages() {
      return Object.keys(this.$i18n.messages).map((id) => ({
        id,
        title: id.toUpperCase(),
      }));
    },
  },
	watch: {
		currentLanguage: {
			async handler(value) {
				setObjectToLocalStorage("lang", value ?? this.$i18n.locale);
				if (value) this.$i18n.locale = value;
				else this.currentLanguage = this.$i18n.locale
			},
			immediate: true
		},
	}
};
</script>

<style scoped>
@media (max-width: 480px) {
  .lang {
    flex-wrap: nowrap;
  }

  .lang .btn {
    min-width: 0;
  }
}
</style>
