<template>
  <v-app class="custom-bg auth-bg">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg5>
            <v-card
              class="elevation-12"
              :loading="isLoading"
              :disabled="isLoading"
            >
              <div class="py-6 px-2">
                <v-card-title class="logo-context">
                  <logo-component color="#5A81F9" :width="52" :height="40" />
                  <div class="logo-title">{{ $t("title") }}</div>
                </v-card-title>
                <div class="text-right">
                  <locale-change-component />
                </div>
                <slot />
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LogoComponent from "@/components/LogoComponent";
import LocaleChangeComponent from "@/components/LocaleChangeComponent.vue";

export default {
  name: "AuthLayout",
  components: { LocaleChangeComponent, LogoComponent },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>

<style scoped>
.auth-bg {
  background: #dceeff !important;
  /*background-image: linear-gradient(90deg,#2dde99,#00b8b0,#6748e7);*/
}

.logo-context {
  justify-content: center;
}
.logo-title {
  padding-left: 6px;
  color: #5a81f9;
}
</style>
