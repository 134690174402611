import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_ROOM_CATEGORIES_PAGINATION_QUERY } from "@/graphql/queries/configuration/room-category/get-room-categories-pagination.query";

const roomCategory = {
  actions: {
    async getRoomCategoriesQuery({ commit }, { skip, take, orderBy, query }) {
      return await handleGqlResponseBody(({ query: queryMethod }) => {
        return queryMethod({
          query: GET_ROOM_CATEGORIES_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default roomCategory;
