import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "./router";
import store from "./store";
import VueToast from "vue-toast-notification";
import VueApollo from "vue-apollo";
import { apolloProvider } from "@/plugins/apollo";
import ShowGqlError from "@/plugins/show-gql-error";
import VueMoment from "vue-moment";
import "./plugins/filters";
import { install as VueMonacoEditorPlugin } from "@guolao/vue-monaco-editor";
import * as _ from "./directives/index";

// Css styles
import "./assets/styles/styles.scss";
import "vue-toast-notification/dist/theme-sugar.css";
import { i18n } from "@/plugins/i18n";
import VueHtml2Canvas from "@/plugins/html2canvas";
import pouchdb from "@/plugins/offlinedb";

// install the vue plugin
Vue.use(VueApollo);
Vue.use(VueToast, {
  position: "bottom-right",
  duration: 5000,
});

Vue.use(VueHtml2Canvas);

const moment = require("moment");
require("moment/locale/ru");
moment.updateLocale("ru", { months: [...i18n.t("date.months.full")] });
Vue.use(VueMoment, {
  moment,
});

Vue.config.productionTip = false;

Vue.use(ShowGqlError, {
  toast: Vue.prototype.$toast,
  i18n,
});

Vue.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs",
  },
});


new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  i18n,
  pouchdb,
  render: (h) => h(App),
}).$mount("#app");
