import gql from 'graphql-tag'

export const GET_CLEANING_ITEMS_PAGINATION_QUERY = gql`
  query GetCleaningItemsPagination (
    $cleanerId: Int
    $status: TodoStatus
    $date: DateString
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
  ) {
    getCleaningItemsPaginated (
      data: {
        cleanerId: $cleanerId
        status: $status
        date: $date
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
      }
    ) {
      hasMore
      total
      items {
        id
        supervisor {
          id
          shortFullName
        }
        cleaner {
          id
          fullName
        }
        room {
          number
          categories {
            title
          }
          # checkin, checkout, guestname
        }
        bookingCheckIn
        bookingCheckOut
        guestName
        notes
        status
        cleaningStatus
        cleaningType
        createdAt
				date
      }
    }
  }
`
