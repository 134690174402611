import { GET_ORGANIZATIONS_PAGINATION_QUERY } from "@/graphql/queries/organization/get-organizations-pagination.query";
import { handleGqlResponseBody } from "@/utils/apollo.util";

const organization = {
  actions: {
    async getOrganizationsQuery({ commit }, { skip, take, orderBy, query }) {
      return await handleGqlResponseBody(({ query: queryMethod }) => {
        return queryMethod({
          query: GET_ORGANIZATIONS_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default organization;
