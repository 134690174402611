import gql from 'graphql-tag'

export const GET_USER_SHEET_DAYS_CONFIG_PAGINATED_QUERY = gql`
  query GetUserSheetDaysConfigPaginated (
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
  ) {
    getUserSheetDaysConfigPaginated (
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
      }
    ) {
      hasMore
      total
      items {
        id
        daysInMonth
        from
        to
      }
    }
  }
`
