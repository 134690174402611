export function getFromLocalStorage (key) {
  return localStorage.getItem(key)
}

export function setToLocalStorage (key, value) {
  localStorage.setItem(key, value)
  return value
}

export function setObjectToLocalStorage (key, value) {
  const obj = JSON.stringify(value)
  localStorage.setItem(key, obj)
  return obj
}

export function getObjectFromLocalStorage (key) {
  const data = localStorage.getItem(key)
  if (!data) return null
  return JSON.parse(data)
}
export function deleteFromLocalStorage (key) {
  localStorage.removeItem(key)
}
