import gql from 'graphql-tag'

export const GET_CASH_BOX_SHIFT_DATA_PAGINATION_QUERY = gql`
  query GetCashBoxShiftDataPagination (
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
    $from: DateTimeIso
    $to: DateTimeIso
    $onlyOpened: Boolean!
  ) {
    getCashBoxShiftDataPaginated (
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
        from: $from
        to: $to
        onlyOpened: $onlyOpened
      }
    ) {
      hasMore
      total
      items {
        id
        cashBox {
          id
          title
        }
        user {
          id
          fullName
          shortFullName
        }
        startShiftPayments {
          id
          payment {
            id
            currencyTitle
            paymentType {
              id
              category {
                title
              }
              title
            }
            amount
          }
          type
        }
        closedShiftPayments {
          id
          payment {
            id
            currencyTitle
            paymentType {
              id
              category {
                title
              }
              title
            }
            amount
          }
          type
        }
        items {
          id
          type
          user {
            id
            fullName
          }
          client {
            id
            fullName
          }
          organization {
            id
            title
          }
          payment {
            id
            currencyTitle
            paymentType {
              id
              category {
                title
              }
              title
            }
            amount
          }
          notes
          source {
            id
            title
            category {
              id
              title
            }
            type
            notShow
            static
          }
          withdrawn
          withdrawReason
          debt {
            id
            leftPayment {
              currencyTitle
              amount
            }
            paidPayment {
              id
              amount
            }
            closed
          }
          createdAt
        }
        differentPayments {
          id
          payment {
            id
            currencyTitle
            paymentType {
              id
              category {
                title
              }
              title
            }
            amount
          }
          reason
          type
        }
        startedAt
        closedAt
      }
    }
  }
`
