module.exports = {
  title: "Eco-Smart",
  validators: {
    required: "Это поле обязательно к заполнению",
    passwordMustMatch: "Пароль должен совпадать",
    invalidEmail: "Неверный адрес электронной почты",
    maxLength: "Поле не должно превышать {length} символов",
    minValue: "Допустимое минимальное значение {value}",
    minArrayValue: "Выберите как минимум {value} элемент | {value} элемента",
    minValueExcluded: "Значение должно быть больше {value}",
    maxValue: "Допустимое максимальное значение {value}",
    selectAtLeastOne: "Выберите хотя бы один вариант",
    firstName: "Требуется имя",
    lastName: "Требуется фамилия",
    notAvailableAtThisDate: "Не доступен(-на) в указанную дату ({date})",
    priceIsNotSetAtThisDate: "Цена не установлена для указанной даты ({date})",
    requiredPriceDefinition: "Цена не определена в системе",
    cashBoxAmountValidator:
      'Значения в колонке "Фактически" должны быть заполнены и не быть отрицательными',
  },
  errorMessages: {
    "Failed to fetch":
      "You have connection issues, please check your internet and try again",
    USER_EMAIL_ALREADY_CREATED:
      "User with this email has been already registered",
    VALUE_MUST_NOT_BE_0: "Сумма должна быть больше 0",
  },
  toolbar: {
    tabs: {
      checkerboard: "Шахматка",
      cashbox: "Касса",
      mainCashbox: "Главная касса",
      userSheet: "Табель",
      todo: "Задача",
      clients: "Клиенты",
      configuration: "Администрирование",
    },
  },
  date: {
    months: {
      short: [
        "янв",
        "фев",
        "мар",
        "апр",
        "май",
        "июнь",
        "июль",
        "авг",
        "сен",
        "окт",
        "ноя",
        "дек",
      ],
      full: [
        "январь",
        "февраль",
        "март",
        "апрель",
        "май",
        "июнь",
        "июль",
        "август",
        "сентябрь",
        "октябрь",
        "ноябрь",
        "декабрь",
      ],
    },
    days: {
      short: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
    },
    times: {
      days: {
        short: "д.",
      },
      hours: {
        short: "ч.",
      },
      minutes: {
        short: "м.",
      },
      seconds: {
        short: "с.",
      },
    },
  },
  pages: {
    common: {
      profile: "Профиль",
      logout: "Выйти",
      loading: "Загрузка",
      save: "Сохранить",
      noData: "Нет данных",
      actions: "Действия",
      room: {
        status: {
          AVAILABLE: "Доступна",
          REPAIRING: "Идет ремонт",
        },
        checkInOutType: {
          NOT_DEFINED: "Не определен",
          FROM_SYSTEM: "С системы",
          HOURLY: "Почасово",
        },
        tariff: {
          service: {
            type: {
              ONE_TIME: "Количественный",
              DAILY: "Ежедневный",
            },
          },
          discount: {
            type: {
              PERCENTAGE: "Процент",
              CUSTOM_PRICE: "Гибкая цена",
            },
          },
        },
      },
      booking: {
        priceTypes: {
          ROOM: "Номер",
          ROOM_HOURLY: "Номер (почасово)",
          EARLY_CHECKIN: "Ранний заезд",
          LATE_CHECKOUT: "Поздний выезд",
          ADDITIONAL_SERVICE: "Дополнительные услуги",
          KITCHEN_MENU: "Питание: меню",
          DISH: "Питание: блюдо",
        },
      },
      kitchen: {
        ingredientUnit: {
          KG: {
            title: "Килограмм",
            shortTitle: "кг.",
          },
          LITER: {
            title: "Литр",
            shortTitle: "л.",
          },
          PIECE: {
            title: "Штук",
            shortTitle: "шт.",
          },
          PORTION: {
            title: "Порция",
            shortTitle: "пор.",
          },
        },
      },
      cancel: "Отмена",
      delete: "Удалить",
      confirmDialog: "Вы действительно хотите выполнить операцию?",
      deletionConfirmation: "Вы действительно хотите удалить?",
      oops: "Упс",
      reload: "Перезагрузить",
      countryNames: {
        AU: "Австралия",
        AT: "Австрия",
        AZ: "Азербайджан",
        AL: "Албания",
        DZ: "Алжир",
        AI: "Ангилья о. (GB)",
        AO: "Ангола",
        AD: "Андорра",
        AQ: "Антарктика",
        AG: "Антигуа и Барбуда",
        AN: "Антильские о‐ва (NL)",
        AR: "Аргентина",
        AM: "Армения",
        AW: "Аруба",
        AF: "Афганистан",
        AX: "Аландские острова",
        BS: "Багамы",
        BD: "Бангладеш",
        BB: "Барбадос",
        BH: "Бахрейн",
        BY: "Беларусь",
        BZ: "Белиз",
        BE: "Бельгия",
        BJ: "Бенин",
        BM: "Бермуды",
        BV: "Бове о. (NO)",
        BG: "Болгария",
        BO: "Боливия",
        BA: "Босния и Герцеговина",
        BW: "Ботсвана",
        BR: "Бразилия",
        BN: "Бруней Дарассалам",
        BF: "Буркина‐Фасо",
        BI: "Бурунди",
        BT: "Бутан",
        VU: "Вануату",
        VA: "Ватикан",
        GB: "Великобритания",
        HU: "Венгрия",
        VE: "Венесуэла",
        VG: "Виргинские о‐ва (GB)",
        VI: "Виргинские о‐ва (US)",
        AS: "Восточное Самоа (US)",
        TP: "Восточный Тимор",
        VN: "Вьетнам",
        GA: "Габон",
        HT: "Гаити",
        GY: "Гайана",
        GM: "Гамбия",
        GH: "Гана",
        GP: "Гваделупа",
        GG: "Гернси",
        GT: "Гватемала",
        GN: "Гвинея",
        GW: "Гвинея‐Бисау",
        DE: "Германия",
        GI: "Гибралтар",
        HN: "Гондурас",
        HK: "Гонконг (CN",
        GD: "Гренада",
        GL: "Гренландия (DK)",
        GR: "Греция",
        GE: "Грузия",
        GU: "Гуам",
        DK: "Дания",
        CD: "Демократическая Республика Конго",
        JE: "Джерси",
        DJ: "Джибути",
        DM: "Доминика",
        DO: "Доминиканская Республика",
        EG: "Египет",
        ZM: "Замбия",
        EH: "Западная Сахара",
        ZW: "Зимбабве",
        IL: "Израиль",
        IN: "Индия",
        ID: "Индонезия",
        JO: "Иордания",
        IQ: "Ирак",
        IR: "Иран",
        IE: "Ирландия",
        IS: "Исландия",
        ES: "Испания",
        IT: "Италия",
        YE: "Йемен",
        CV: "Кабо‐Верде",
        KZ: "Казахстан",
        KY: "Каймановы о‐ва (GB)",
        KH: "Камбоджа",
        CM: "Камерун",
        CA: "Канада",
        QA: "Катар",
        KE: "Кения",
        CY: "Кипр",
        KG: "Кыргызстан",
        KI: "Кирибати",
        CN: "Китай",
        CC: "Кокосовые (Киилинг) о‐ва (AU)",
        CO: "Колумбия",
        KM: "Коморские о‐ва",
        CG: "Конго",
        CR: "Коста‐Рика",
        CI: "Кот‐д'Ивуар",
        CU: "Куба",
        KW: "Кувейт",
        CK: "Кука о‐ва (NZ)",
        LA: "Лаос",
        LV: "Латвия",
        LS: "Лесото",
        LR: "Либерия",
        LB: "Ливан",
        LY: "Ливия",
        LT: "Литва",
        LI: "Лихтенштейн",
        LU: "Люксембург",
        MU: "Маврикий",
        MR: "Мавритания",
        MG: "Мадагаскар",
        YT: "Майотта о. (KM)",
        MO: "Макао (PT)",
        MK: "Македония",
        MW: "Малави",
        MF: "Сент Мартин",
        MY: "Малайзия",
        ML: "Мали",
        MV: "Мальдивы",
        MT: "Мальта",
        MA: "Марокко",
        MQ: "Мартиника",
        MH: "Маршалловы о‐ва",
        MX: "Мексика",
        FM: "Микронезия (US)",
        MZ: "Мозамбик",
        MD: "Молдова",
        MC: "Монако",
        MN: "Монголия",
        MS: "Монсеррат о. (GB)",
        MM: "Мьянма",
        NA: "Намибия",
        NR: "Науру",
        NP: "Непал",
        NE: "Нигер",
        NG: "Нигерия",
        NL: "Нидерланды",
        NI: "Никарагуа",
        NU: "Ниуэ о. (NZ)",
        NZ: "Новая Зеландия",
        NC: "Новая Каледония о. (FR)",
        NO: "Норвегия",
        NF: "Норфолк о. (AU)",
        AE: "Объединенные Арабские Эмираты",
        OM: "Оман",
        IM: "Остров Мэн",
        PK: "Пакистан",
        PW: "Палау (US)",
        PS: "Палестинская автономия",
        PA: "Панама",
        PG: "Папуа‐Новая Гвинея",
        PY: "Парагвай",
        PE: "Перу",
        PN: "Питкэрн о‐ва (GB)",
        PL: "Польша",
        PT: "Португалия",
        PR: "Пуэрто‐Рико (US)",
        RE: "Реюньон о. (FR)",
        CX: "Рождества о. (AU)",
        RU: "Россия",
        RW: "Руанда",
        RO: "Румыния",
        SV: "Сальвадор",
        WS: "Самоа",
        SM: "Сан Марино",
        ST: "Сан‐Томе и Принсипи",
        SA: "Саудовская Аравия",
        SZ: "Свазиленд",
        SJ: "Свалбард и Ян Мейен о‐ва (NO)",
        SH: "Святой Елены о. (GB)",
        KP: "Северная Корея (КНДР)",
        MP: "Северные Марианские",
        SC: "Сейшелы",
        VC: "Сен‐Винсент и Гренадины",
        PM: "Сен‐Пьер и Микелон (FR)",
        SN: "Сенегал",
        KN: "Сент‐Кристофер и Невис",
        LC: "Сент‐Люсия",
        BL: "Сен-Бартелеми",
        RS: "Сербия",
        SG: "Сингапур",
        SY: "Сирия",
        SK: "Словакия",
        SI: "Словения",
        US: "Соединенные Штаты Америки",
        SB: "Соломоновы о‐ва",
        SO: "Сомали",
        SD: "Судан",
        SR: "Суринам",
        SL: "Сьерра‐Леоне",
        SS: "Южный Судан",
        TJ: "Таджикистан",
        TH: "Таиланд",
        TW: "Тайвань",
        TZ: "Танзания",
        TL: "Тимор-Лешти",
        TC: "Теркс и Кайкос о‐ва (GB)",
        TG: "Того",
        TK: "Токелау о‐ва (NZ)",
        TO: "Тонга",
        TT: "Тринидад и Тобаго",
        TV: "Тувалу",
        TN: "Тунис",
        TM: "Туркменистан",
        TR: "Турция",
        UG: "Уганда",
        UZ: "Узбекистан",
        UA: "Украина",
        WF: "Уоллис и Футунао‐ва (FR)",
        UY: "Уругвай",
        FO: "Фарерские о‐ва (DK)",
        FJ: "Фиджи",
        PH: "Филиппины",
        FI: "Финляндия",
        FK: "Фолклендские (Мальвинские) о‐ва (GB/AR)",
        FR: "Франция",
        GF: "Французская Гвиана (FR)",
        PF: "Французская Полинезия",
        HM: "Херд и Макдональд о‐ва (AU)",
        HR: "Хорватия",
        CF: "Центрально‐африканская Республика",
        TD: "Чад",
        ME: "Черногория",
        CZ: "Чехия",
        CL: "Чили",
        CH: "Швейцария",
        SE: "Швеция",
        LK: "Шри‐Ланка",
        EC: "Эквадор",
        GQ: "Экваториальная Гвинея",
        ER: "Эритрия",
        EE: "Эстония",
        ET: "Эфиопия",
        YU: "Югославия",
        ZA: "Южная Африка",
        GS: "Южная Георгия и Южные Сандвичевы о‐ва",
        KR: "Южная Корея (Республика Корея)",
        JM: "Ямайка",
        JP: "Япония",
        TF: "Французские южные территории (FR)",
        IO: "Британская территория Индийского океана (GB)",
        UM: "Соединенные Штаты Америки Внешние малые острова (US)",
      },
      languageNames: {
        EN: "Английский",
        ES: "Испанский",
        ZH: "Китайский (мандарин)",
        HI: "Хинди",
        AR: "Арабский",
        FR: "Французский",
        RU: "Русский",
        BN: "Бенгальский",
        PT: "Португальский",
        UR: "Урду",
        ID: "Индонезийский",
        DE: "Немецкий",
        JA: "Японский",
        SW: "Суахили",
        KO: "Корейский",
        TR: "Турецкий",
        IT: "Итальянский",
        NL: "Голландский",
        PL: "Польский",
        VI: "Вьетнамский",
        TA: "Тамильский",
        TE: "Телугу",
        MR: "Маратхи",
        TH: "Тайский",
        PA: "Панджаби",
        ML: "Малаялам",
        YO: "Йоруба",
        TL: "Тагальский",
        SD: "Синдхи",
        FA: "Фарси (персидский)",
        SV: "Шведский",
        EL: "Греческий",
        CS: "Чешский",
        HU: "Венгерский",
        HE: "Иврит",
        FI: "Финский",
        DA: "Датский",
        NO: "Норвежский",
        IS: "Исландский",
        SK: "Словацкий",
        SL: "Словенский",
        HR: "Хорватский",
        BS: "Боснийский",
        SR: "Сербский",
        ME: "Черногорский",
        SQ: "Албанский",
        MK: "Македонский",
        BG: "Болгарский",
        RO: "Румынский",
        MO: "Молдавский",
        UK: "Украинский",
        BE: "Белорусский",
        LV: "Латышский",
        LT: "Литовский",
        ET: "Эстонский",
        KA: "Грузинский",
        HY: "Армянский",
        AZ: "Азербайджанский",
        KK: "Казахский",
        KY: "Киргизский",
        TK: "Туркменский",
        UZ: "Узбекский",
        TG: "Таджикский",
        PS: "Пашто",
        "FA-AF": "Дари",
        KU: "Курдский",
        BAL: "Белуджский",
        BO: "Тибетский",
        NE: "Непальский",
        SI: "Сингальский",
        MY: "Бирманский",
        KM: "Кхмерский",
        LO: "Лаосский",
        MS: "Малайский",
        JV: "Яванский",
        SU: "Сунданский",
        MAD: "Мадурский",
        HA: "Хауса",
        IG: "Игбо",
        KN: "Каннада",
        MG: "Малагасийский",
        FJ: "Фиджийский",
        TO: "Тонганский",
        SM: "Самоанский",
        MI: "Маори",
      },
    },
    login: {
      title: "Вход",
      yourEmail: "Ваш email",
      password: "Пароль",
      forgotYourPassword: "Забыли пароль?",
      submit: "Войти",
    },
    passwordRecovery: {
      title: "Восстановление пароля",
      enterYourEmail: "Введите ваш email",
      rememberYourPassword: "Вспомнили пароль?",
      submit: "Восстановить",
      instructionDescription:
        "Инструкция по восстановлению пароля отправлена, пожалуйста проверьте вашу почту",
      goToLoginScreen: "Перейти на страницу входа",
    },
    passwordRecoveryConfirmation: {
      title: "Подтверждение восстановления пароля",
      invalidResetTokenDescription:
        "Неверный токен. Пожалуйста попробуйте восстановить ваш пароль заново",
      goToPasswordRecoveryScreen: "Перейти на страницу восстановления пароля",
      enterNewPassword: "Введите новый пароль",
      confirmYourNewPassword: "Подтвердите ваш новый пароль",
      submit: "Изменить пароль",
      yourPasswordSuccessfullyChanged: "Ваш пароль изменен успешно",
      goToLoginScreen: "Перейти на страницу входа",
    },
    logout: {
      loggingOut: "Идет выход...",
    },
    checkerboard: {
      roomCategory: "Категория комнаты",
      dateRanges: {
        WEEK: "Неделя",
        MONTH: "Месяц",
        MONTH3: "3 Месяца",
      },
      createBooking: "Создать бронь",
      bookingCreation: {
        title: "Новая бронь",
        selectPhoto: "Выбрать фото",
        newOrganization: "Новая организация",
        selectedClient: "Выбран клиент:",
        fullName: {
          label: "ФИО клиента",
          placeholder: "Напр.: Иванов Иван",
        },
        telNumber: {
          label: "Тел. номер",
          placeholder: "Напр.: +996 500 001 002",
        },
        citizenship: {
          label: "Гражданство",
          placeholder: "Напр.: Кыргызстан",
        },
        email: {
          label: "Электронная почта",
          placeholder: "Напр.: example@mail.com",
        },
        organization: {
          label: "Организация",
          placeholder: "Напр.: AFC",
        },
        fromDate: {
          label: "Дата заезда",
          placeholder: "Выберите дату заезда",
        },
        toDate: {
          label: "Дата выезда",
          placeholder: "Выберите дату выезда",
        },
        earlyCheckInTime: {
          label: "Время заезда",
          placeholder: "Выберите время заезда",
        },
        lateCheckOutTime: {
          label: "Время выезда",
          placeholder: "Выберите время выезда",
        },
        earlyOrLateCheckInOutTime: {
          label: "Ранний заезд или поздний выезд",
        },
        earlyOrLateCheckInOutAdditionalFee: {
          label: "Стоимость",
        },
        numberOfAdults: {
          label: "Взрослые",
        },
        numberOfChildren: {
          label: "Дети",
        },
        numberOfNights: {
          label: "Количество ночей",
        },
        room: {
          label: "Комната",
          placeholder: "Выберите комнату",
        },
        roomTariff: {
          label: "Тариф",
          placeholder: "Выберите тариф",
        },
        roomTariffDiscount: {
          label: "Скидка",
          placeholder: "Выберите скидку",
        },
        paymentTotal: {
          label: "Итого к оплате",
        },
        paymentSum: {
          label: "Оплата",
          placeholder: "Введите сумму",
        },
        paymentType: {
          label: "Тип оплаты",
          placeholder: "Выберите тип оплаты",
        },
        notes: {
          label: "Примечание",
          placeholder: "Оставьте примечание по брони",
        },
      },
      item: {
        information: "Информация",
        fullName: "ФИО",
        group: "Группа",
        status: "Статус",
        telNumber: "Тел. номер",
        organization: "Организация",
        fromDate: "Дата заезда",
        toDate: "Дата выезда",
        earlyCheckin: "Ранний заезд",
        lateCheckout: "Поздний выезд",
        numberOfGuests: "Количество гостей",
        paid: "Оплачено",
        debt: "Долг",
        sumToPay: "Сумма к оплате",
        room: "Комната",
        roomCategory: "Категория",
        tariff: "Тариф",
        notes: "Примечание",
      },
      card: {
        title: "Бронирование",
        groups: {
          client: {
            groupName: "Клиент",
            form: {
              fullName: {
                label: "ФИО",
                placeholder: "ФИО клиента",
              },
              telNumber: {
                label: "Тел. номер",
                placeholder: "+996 (500) 000-001",
              },
              citizenship: {
                label: "Гражданство",
                placeholder: "Гражданство",
              },
              hasRepresentative: {
                label: "Добавить клиента",
              },
            },
          },
          group: {
            groupName: "Группа",
            form: {
              title: {
                label: "Название группы",
                placeholder: "Напр.: Футболисты из Узбекистана",
              },
            },
          },
          organization: {
            groupName: "Организация",
            form: {
              hasOrganization: {
                label: "Добавить организацию",
              },
              title: {
                label: "Название",
                placeholder: "Напр.: AFC",
              },
              telNumber: {
                label: "Тел. номер",
                placeholder: "Напр.: +996 (500) 000-001",
              },
            },
          },
          booking: {
            groupName: "Детали брони",
            form: {
              room: {
                label: "Номер",
                placeholder: "Название номера",
              },
              tariff: {
                label: "Тариф",
                placeholder: "Название тарифа",
              },
              categories: {
                label: "Категории",
                placeholder: "Название категории",
              },
              numberOfAdults: {
                label: "Взрослые",
              },
              numberOfChildren: {
                label: "Дети",
              },
              fromDate: {
                label: "Дата заезда",
                placeholder: "Выберите дату",
              },
              toDate: {
                label: "Дата выезда",
                placeholder: "Выберите дату",
              },
              fromTime: {
                label: "Время заезда",
                placeholder: "Выберите время",
              },
              toTime: {
                label: "Время выезда",
                placeholder: "Выберите время",
              },
              numberOfNights: {
                label: "Кол-во ночей",
              },
              numberOfHours: {
                label: "Кол-во часов",
              },
              earlyOrLateCheckInOutTime: {
                label: "Ранний заезд или поздний выезд",
              },
              earlyCheckInTime: {
                label: "Ранний заезд",
                placeholder: "Выберите",
              },
              lateCheckOutTime: {
                label: "Поздний выезд",
                placeholder: "Выберите",
              },
            },
            addRoom: "Добавить номер",
            categories: "Категории",
            tariff: "Тариф",
            fromDate: "Дата заезда",
            toDate: "Дата выезда",
            earlyCheckin: "Ранний заезд",
            lateCheckout: "Поздний выезд",
            numberOfNights: "Количество ночей",
            numberOfAdults: "Количество взрослых",
            numberOfChildren: "Количество детей",
            quickFilling: "Быстрое заполнение",
            quickFillingModal: {
              title: "Быстрое заполнение",
              form: {
                rooms: {
                  label: "Номера",
                  placeholder: "Название номеров",
                },
                tariff: {
                  label: "Тариф",
                  placeholder: "Название тарифа",
                },
              }
            }
          },
          nutrition: {
            groupName: "Питание",
            kitchenMenu: {
              table: {
                title: "Название",
                date: "Дата подачи",
                price: "Цена",
                quantity: "Количество",
                discount: "Cкидка",
                total: "Итого",
              },
            },
            dish: {
              table: {
                title: "Название",
                date: "Дата подачи",
                price: "Цена",
                quantity: "Количество",
                discount: "Cкидка",
                total: "Итого",
              },
            },
          },
          additionalService: {
            groupName: "Дополнительные услуги",
            table: {
              title: "Название",
              date: "Дата подачи",
              price: "Цена",
              quantity: "Количество",
              discount: "Cкидка",
              total: "Итого",
            },
          },
          payment: {
            groupName: "Оплата",
            table: {
              title: "Название",
              date: "Дата",
              price: "Цена",
              quantity: "Количество",
              discount: "Cкидка",
              total: "Итого",
            },
          },
          cashBox: {
            groupName: "Касса",
            notAvailable:
              "Касса не доступна при просмотре / редактировании <b>группового</b> бронирования",
            notAvailableCashBoxFound: "Доступных открытых вами касс не найдено",
            table: {
              value: "Сумма",
              paymentType: "Тип оплаты",
              createdAt: "Дата",
              createdBy: "Оплачено",
              notes: "Примечание",
            },
            form: {
              cashBox: {
                label: "Кассовый аппарат",
                placeholder: "Выберите кассовый аппарат",
              },
              total: {
                label: "Итого",
              },
              paidSum: {
                label: "Оплачено",
              },
              sumToPay: {
                label: "К оплате",
              },
              totalSumToPay: {
                label: "Итого к оплате",
              },
              discount: {
                label: "Скидка",
                placeholder: "Выберите скидку",
              },
              discountAmount: {
                label: "Значение скидки",
                placeholder: "Введите значение скидки",
              },
              payment: {
                label: "Оплата",
                placeholder: "Введите сумму оплаты",
              },
              paymentType: {
                label: "Тип оплаты",
                placeholder: "Выберите тип оплаты",
              },
              currency: {
                label: "Валюта",
                placeholder: "Выберите валюту",
              },
            },
          },
        },
        nutrition: {
          title: "Питание",
          kitchenMenu: {
            title: "Меню",
            table: {
              title: "Название",
              date: "Дата подачи",
              price: "Цена",
              quantity: "Количество",
              form: {
                kitchenMenu: {
                  placeholder: "Выберите меню",
                },
                everyDay: {
                  label: "Каждый день",
                },
                date: {
                  placeholder: "Выберите дату",
                },
                price: {
                  label: "Цена",
                },
                quantity: {
                  placeholder: "Введите количество",
                },
              },
            },
          },
          dishes: {
            title: "Блюда",
            table: {
              title: "Название",
              date: "Дата подачи",
              price: "Цена",
              quantity: "Количество",
              form: {
                dish: {
                  placeholder: "Выберите блюдо",
                },
                everyDay: {
                  label: "Каждый день",
                },
                date: {
                  placeholder: "Выберите дату",
                },
                price: {
                  label: "Цена",
                },
                quantity: {
                  placeholder: "Введите количество",
                },
              },
            },
          },
        },
        additionalServices: {
          title: "Дополнительные услуги",
          table: {
            title: "Название",
            date: "Дата",
            price: "Цена",
            quantity: "Количество",
            form: {
              service: {
                placeholder: "Выберите сервис",
              },
              date: {
                placeholder: "Выберите дату",
              },
              price: {
                label: "Цена",
              },
              quantity: {
                placeholder: "Введите количество",
              },
              everyDay: {
                label: "Каждый день",
              },
            },
          },
        },
      },
      infographics: {
        load: {
          today: "Загрузка на сегодня",
          booked: "Всего заняты",
          rooms: "Всего номеров",
        },
        numbers: {
          living: "Проживающие",
          checkOuts: "Выезды",
          bookings: "Бронь",
          inRepair: "Ремонт",
          inDebt: "Задолжник",
          cancelled: "Отмена брони",
        },
        roomStates: {
          roomsState: "Состояние номеров",
          cleaned: "Убрано",
          dirty: "Грязно",
          inRepair: "Ремонт",
        },
      },
      roomInfoCard: {
        title: "Выберите тип бронирования",
        singleBooking: "Одиночное заселение",
        groupBooking: "Групповое заселение",
        repair: "Ремонт",
      },
      infoCard: {
        payment: {
          title: "Оплата",
          price: {
            table: {
              title: "Название",
              date: "Дата",
              price: "Цена",
              quantity: "Количество",
              discount: "Cкидка",
              total: "Итого",
            },
          },
          cashBox: {
            notAvailableCashBoxFound: "Доступных открытых вами касс не найдено",
            form: {
              cashBox: {
                label: "Кассовый аппарат",
                placeholder: "Выберите кассовый аппарат",
              },
              total: {
                label: "Итого",
              },
              paidSum: {
                label: "Оплачено",
              },
              sumToPay: {
                label: "К оплате",
              },
              totalSumToPay: {
                label: "Итого к оплате",
              },
              discount: {
                label: "Скидка",
                placeholder: "Выберите скидку",
              },
              discountAmount: {
                label: "Значение скидки",
                placeholder: "Введите значение скидки",
              },
              payment: {
                label: "Оплата",
                placeholder: "Введите сумму оплаты",
              },
              paymentType: {
                label: "Тип оплаты",
                placeholder: "Выберите тип оплаты",
              },
              currency: {
                label: "Валюта",
                placeholder: "Выберите валюту",
              },
            },
            table: {
              value: "Сумма",
              paymentType: "Тип оплаты",
              createdAt: "Дата",
              createdBy: "Оплачено",
              notes: "Примечание",
            },
          },
        },
        cancel: {
          title: "Отмена брони",
          price: {
            table: {
              title: "Название",
              date: "Дата",
              price: "Цена",
              quantity: "Количество",
              discount: "Cкидка",
              total: "Итого",
            },
          },
          cashBox: {
            info: "Отмена брони снимает с кассы сумму оплаченной предоплаты",
            notAvailableCashBoxFound: "Доступных открытых вами касс не найдено",
            paid: "Оплачено",
            form: {
              returnPayment: {
                label: "Вернуть оплату",
              },
              payment: {
                label: "Оплата",
                placeholder: "Введите сумму оплаты",
              },
              cashBox: {
                label: "Кассовый аппарат",
                placeholder: "Выберите кассовый аппарат",
              },
              paymentType: {
                label: "Тип оплаты",
                placeholder: "Выберите тип оплаты",
              },
              currency: {
                label: "Валюта",
                placeholder: "Выберите валюту",
              },
            },
            table: {
              value: "Сумма",
              paymentType: "Тип оплаты",
              createdAt: "Дата",
              createdBy: "Оплачено",
              notes: "Примечание",
            },
          },
        },
        repair: {
          title: "Ремонт - {title}",
          cancelRoomRepair: "Отменить ремонт",
        },
        changeRoom: {
          title: "Переезд",
          form: {
            room: {
              label: "Номер",
              placeholder: "Выберите номер",
            },
          },
        },
        invoice: {
          title: "Квитанция",
          form: {
            language: {
              label: "Язык",
              placeholder: "Выберите язык",
            },
            individualClient: {
              label: "Индивидуальный клиент",
            },
            bookingRepresentative: {
              label: "Клиент",
              placeholder: "Выберите клиента",
            },
          },
        },
      },
    },
    configuration: {
      main: {
        description: "Выберите нужную вам конфигурацию",
      },
      systemConfigurations: {
        title: "Системные конфигурации",
        configurationTypes: {
          time: {
            label: "Время",
            placeholder: "Выберите время",
          },
          percentage: {
            label: "Процент",
            placeholder: "Введите процент",
          },
          file: {
            label: "Файл",
            placeholder: "Выберите файл",
          },
        },
        configurations: {
          booking: {
            title: "Бронирование",
            items: {
              additionalFeePercentageForEarlyCheckIn:
                "Дополнительная плата за ранний заезд",
              additionalFeePercentageForLateCheckOut:
                "Дополнительная плата за поздний выезд",
              checkInTime: "Время заезда",
              checkOutTime: "Время выезда",
            },
          },
          template: {
            title: "Шаблон",
            items: {
              logo: "Логотип",
              stamp: "Печать",
              watermark: "Водяной знак",
            },
          },
        },
      },
      cashBox: {
        title: "Касса",
        box: {
          title: "Кассовые аппараты",
          addModal: {
            title: "Добавление кассового аппарата",
          },
          editModal: {
            title: "Редактирование кассового аппарата",
          },
        },
        sourceCategory: {
          title: "Категории источников",
          addModal: {
            title: "Добавление категории источников",
          },
          editModal: {
            title: "Редактирование категории источников",
          },
        },
        source: {
          title: "Источники",
          addModal: {
            title: "Добавление источника",
          },
          editModal: {
            title: "Редактирование источника",
          },
          label: {
            category: "Категория",
            relatedTo: "Относится к",
            prePrice: "Предоценка (не обязательно)",
            static: "Статичная цена",
          },
          types: {
            income: "Доход",
            expense: "Расход",
          },
        },
      },
      rooms: {
        title: "Номера",
        rooms: {
          title: "Номера",
          searchByTitle: "Поиск по названию",
          deletionConfirmation: "Вы действительно хотите удалить?",
          add: "Добавить",
          addModal: {
            title: "Добавление новой комнаты",
            form: {
              number: {
                label: "Номер",
                placeholder: "Введите номер комнаты",
              },
              title: {
                label: "Название",
                placeholder: "Введите название комнаты",
              },
              description: {
                label: "Описание",
                placeholder: "Введите описание комнаты",
              },
              capacity: {
                label: "Вместимость",
                placeholder: "Вместимость комнаты",
              },
              multiBooking: {
                label: "Параллельное бронирование",
              },
              totalCapacity: {
                label: "Общая вместимость",
                placeholder: "Общая вместимость комнаты",
              },
              checkInOutType: {
                label: "Тип бронирования",
                placeholder: "Выберите тип бронирования",
              },
              floor: {
                label: "Этаж",
                placeholder: "Выберите этаж",
              },
              categories: {
                label: "Категории",
                placeholder: "Выберите категории комнаты",
              },
            },
          },
          editModal: {
            title: "Редактирование комнаты",
          },
          table: {
            floor: "Этаж",
            title: "Название",
            number: "Номер",
            categories: "Категории",
            capacity: "Вместимость",
            checkInOutType: "Тип бронирования",
            actions: "Действия",
          },
          tariffs: {
            discounts: {
              editModal: {
                title: "Редактирование скидок тарифа",
                discounts: "Скидки",
                form: {
                  roomCategoryTitle: "Название",
                  onlyForRoom: {
                    label: "Только для проживания",
                  },
                  type: {
                    label: "Тип скидки",
                    placeholder: "Выберите тип скидки",
                  },
                  value: {
                    label: "Значение",
                    placeholder: "Введите значение",
                  },
                  maxValue: {
                    label: "Макс. значение",
                    placeholder: "Введите значение",
                  },
                },
              },
            },
          },
        },
        floors: {
          title: "Этажи",
          searchByTitle: "Поиск по названию",
          searchByFloor: "Поиск по этажам",
          deletionConfirmation: "Вы действительно хотите удалить?",
          add: "Добавить",
          addModal: {
            title: "Добавление нового этажа",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название этажа",
              },
            },
          },
          editModal: {
            title: "Редактирование этажа",
          },
          table: {
            title: "Название",
            actions: "Действия",
          },
        },
        roomCategories: {
          title: "Категории номеров",
          searchByTitle: "Поиск по названию",
          deletionConfirmation: "Вы действительно хотите удалить?",
          add: "Добавить",
          addModal: {
            title: "Добавление новой категории",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название категории",
              },
            },
          },
          editModal: {
            title: "Редактирование категории",
          },
          table: {
            title: "Название",
            actions: "Действия",
          },
        },
        tariffs: {
          title: "Тарифы проживания",
          searchByTitle: "Поиск по названию",
          deletionConfirmation: "Вы действительно хотите удалить?",
          add: "Добавить",
          addModal: {
            title: "Добавление нового тарифа",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название тарифа",
              },
              description: {
                label: "Описание",
                placeholder: "Введите описание тарифа",
              },
            },
          },
          editModal: {
            title: "Редактирование тарифа",
          },
          table: {
            title: "Название",
            description: "Описание",
            actions: "Действия",
          },
        },
        services: {
          title: "Сервисы",
          searchByTitle: "Поиск по названию",
          deletionConfirmation: "Вы действительно хотите удалить?",
          add: "Добавить",
          addModal: {
            title: "Добавление нового сервиса",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название сервиса",
              },
              fromDate: {
                label: "Действует с",
                placeholder: "Выберите дату",
              },
              toDate: {
                label: "До",
                placeholder: "Выберите дату",
              },
              fromTime: {
                label: "Время c",
                placeholder: "Выберите время",
              },
              toTime: {
                label: "До",
                placeholder: "Выберите время",
              },
            },
          },
          editModal: {
            title: "Редактирование сервиса",
          },
          table: {
            title: "Название",
            actions: "Действия",
            date: "Время действия",
          },
        },
      },
      userManagement: {
        title: "Управление Пользователи",
        users: {
          title: "Пользователи",
          add: "Добавить",
          searchQuery: "Поиск по имени / тел.",
          userGroup: "Группа",
          isActive: "Активность",
          deletionConfirmation: "Вы действительно хотите удалить?",
          addModal: {
            title: "Добавление нового пользователя",
            form: {
              email: {
                label: "Email",
                placeholder: "Введите email",
              },
              firstName: {
                label: "Имя",
                placeholder: "Введите имя",
              },
              lastName: {
                label: "Фамилия",
                placeholder: "Введите фамилию",
              },
              patronymic: {
                label: "Отчество",
                placeholder: "Введите отчество",
              },
              telNumber: {
                label: "Tел. номер",
                placeholder: "Введите тел. номер",
              },
              userGroup: {
                label: "Группа пользователя",
                placeholder: "Выберите группу пользователя",
              },
              address: {
                label: "Адрес",
                placeholder: "Введите адрес",
              },
              isActive: {
                label: "Активность",
              },
            },
          },
          editModal: {
            title: "Редактирование пользователя",
          },
          table: {
            id: "ID",
            fullName: "ФИО",
            email: "Email",
            telNumber: "Тел. номер",
            userGroup: "Группа",
            actions: "Действия",
          },
        },
        userGroups: {
          title: "Группы пользователей",
          add: "Добавить",
          userGroup: "Группа",
          deletionConfirmation: "Вы действительно хотите удалить?",
          addModal: {
            title: "Добавление новой группы",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название",
              },
              privileges: {
                label: "Привилегии",
                placeholder: "Выберите привилегии",
              },
            },
          },
          editModal: {
            title: "Редактирование группы",
          },
          table: {
            title: "Название",
            privileges: "Привилегии",
            actions: "Действия",
          },
        },
      },
      kitchen: {
        title: "Кухня",
        menus: {
          title: "Меню",
          searchByTitle: "Поиск по названию",
          deletionConfirmation: "Вы действительно хотите удалить?",
          add: "Добавить",
          addModal: {
            title: "Добавление нового меню",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название меню",
              },
              description: {
                label: "Описание",
                placeholder: "Введите описание меню",
              },
              fromDate: {
                label: "Действует с",
                placeholder: "Выберите дату",
              },
              toDate: {
                label: "До",
                placeholder: "Выберите дату",
              },
              fromTime: {
                label: "Время подачи",
                placeholder: "Выберите время",
              },
              toTime: {
                label: "До",
                placeholder: "Выберите время",
              },
              table: {
                title: "Название",
                value: "Количество",
                form: {
                  dish: {
                    label: "Блюдо",
                    placeholder: "Выберите блюдо",
                  },
                  value: {
                    label: "Количество",
                    placeholder: "Введите количество",
                  },
                },
              },
            },
            dishes: "Блюда",
          },
          editModal: {
            title: "Редактирование меню",
          },
          table: {
            title: "Название",
            description: "Описание",
            dishes: "Блюда",
            date: "Время действия",
            actions: "Действия",
          },
        },
        dishes: {
          title: "Блюда",
          searchByTitle: "Поиск по названию",
          deletionConfirmation: "Вы действительно хотите удалить?",
          add: "Добавить",
          addModal: {
            title: "Добавление нового блюда",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название блюда",
              },
              unit: {
                label: "Единица измерения",
                placeholder: "Выберите единицу измерения",
              },
              description: {
                label: "Описание",
                placeholder: "Введите описание блюда",
              },
              fromDate: {
                label: "Действует с",
                placeholder: "Выберите дату",
              },
              toDate: {
                label: "До",
                placeholder: "Выберите дату",
              },
              fromTime: {
                label: "Время подачи",
                placeholder: "Выберите время",
              },
              toTime: {
                label: "До",
                placeholder: "Выберите время",
              },
              table: {
                title: "Название",
                value: "Количество",
                servingDate: "Количество",
                form: {
                  ingredient: {
                    label: "Ингредиент",
                    placeholder: "Выберите ингредиент",
                  },
                  value: {
                    label: "Количество",
                    placeholder: "Введите количество",
                  },
                },
              },
            },
            ingredients: "Ингредиенты",
          },
          editModal: {
            title: "Редактирование блюда",
          },
          table: {
            title: "Название",
            unit: "Единица измерения",
            description: "Описание",
            ingredients: "Ингредиенты",
            date: "Время действия",
            actions: "Действия",
          },
        },
        ingredients: {
          title: "Ингредиенты",
          searchByTitle: "Поиск по названию",
          deletionConfirmation: "Вы действительно хотите удалить?",
          add: "Добавить",
          addModal: {
            title: "Добавление нового ингредиента",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название ингредиента",
              },
              unit: {
                label: "Единица измерения",
                placeholder: "Выберите единицу измерения",
              },
            },
          },
          editModal: {
            title: "Редактирование ингредиента",
          },
          table: {
            title: "Название",
            unit: "Единица измерения",
            actions: "Действия",
          },
        },
      },
      finance: {
        title: "Финансы",
        roomCategories: {
          title: "Категории номеров",
          searchByTitle: "Поиск по названию",
          editModal: {
            title: "Редактирование цен",
            form: {
              title: "Название",
              fromDate: {
                label: "Дата с",
                placeholder: "Выберите дату",
              },
              toDate: {
                label: "по",
                placeholder: "Выберите дату",
              },
              price: {
                label: "Цена",
                placeholder: "Введите цену",
              },
              service: {
                label: "Услуга",
                placeholder: "Выберите услугу",
              },
              serviceType: {
                label: "Тип",
                placeholder: "Выберите тип услуги",
              },
              count: {
                label: "Количество",
                placeholder: "Введите количество",
              },
              kitchenMenu: {
                label: "Меню",
                placeholder: "Выберите меню",
              },
              fixed: {
                label: "Зафиксированный",
              },
            },
            prices: "Цены",
            services: "Услуги",
            kitchenMenus: "Меню",
          },
          table: {
            title: "Название",
            price: "Цена",
            actions: "Действия",
          },
        },
        dishes: {
          title: "Блюда",
          searchByTitle: "Поиск по названию",
          editModal: {
            title: "Редактирование цен",
            form: {
              title: "Название",
              fromDate: {
                label: "Дата с",
                placeholder: "Выберите дату",
              },
              toDate: {
                label: "по",
                placeholder: "Выберите дату",
              },
              price: {
                label: "Цена",
                placeholder: "Введите цену",
              },
            },
            prices: "Цены",
          },
          table: {
            title: "Название",
            price: "Цена",
            actions: "Действия",
          },
        },
        services: {
          title: "Сервисы",
          searchByTitle: "Поиск по названию",
          editModal: {
            title: "Редактирование цен",
            form: {
              title: "Название",
              fromDate: {
                label: "Дата с",
                placeholder: "Выберите дату",
              },
              toDate: {
                label: "по",
                placeholder: "Выберите дату",
              },
              price: {
                label: "Цена",
                placeholder: "Введите цену",
              },
            },
            prices: "Цены",
          },
          table: {
            title: "Название",
            price: "Цена",
            actions: "Действия",
          },
        },
        paymentTypeCategories: {
          title: "Категории типов оплат",
          addModal: {
            title: "Добавление новой категории типа оплаты",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название категории типа оплаты",
              },
            },
          },
          editModal: {
            title: "Редактирование категории типа оплаты",
          },
          table: {
            title: "Название",
            actions: "Действия",
          },
        },
        paymentTypes: {
          title: "Типы оплат",
          addModal: {
            title: "Добавление нового типа оплаты",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название типа оплаты",
              },
              type: {
                label: "Категория типа оплаты",
              },
            },
          },
          editModal: {
            title: "Редактирование типа оплаты",
          },
          table: {
            title: "Название",
            type: "Тип оплаты",
            actions: "Действия",
          },
        },
        exchangeRateGroups: {
          title: "Обмен валют",
          addModal: {
            title: "Добавление нового обмена валют",
            form: {
              fromDate: {
                label: "Дата активации",
                placeholder: "Выберите дату",
              },
              defaultCurrency: {
                label: "Основная валюта",
              },
              currency: {
                label: "Валюта",
              },
              currencyValue: {
                label: "Курс",
                placeholder: "Введите курс",
              },
            },
          },
          table: {
            createdAt: "Дата создания",
            fromDate: "Дата активации",
            defaultCurrency: "Основная валюта",
          },
        },
      },
      userSheet: {
        title: "Табель",
        rates: {
          title: "Ставки",
          searchByPosition: "Поиск по должности",
          position: "Должность",
          shiftName: "Смена",
          coefficient: "Коэфф.",
          workHours: "Раб. часы",
          actualRate: "Актуальная ставка",
          rate: "Ставка",
          paymentFrom: "Оплата с",
          paymentTo: "Оплата до",
          actions: "Действия",
          modal: {
            addTitle: "Добавление ставки",
            editTitle: "Редактирование ставки",
            showHistoryTitle: "История оплаты для смены",
            doNotFillFields: 'Оставьте поля "Рабочие часы" и "Ставка" пустыми тех смен, которые не будут отображаться в табеле для этой должности'
          },
          labels: {
            userGroup: "Должность",
            shift: "Смена",
            coefficient: "Коэффициент",
            workHours: "Рабочие часы",
            actualRate: "Актуальная ставка",
            from: "от",
            rate: "Ставка",
            paymentFrom: "Оплата с",
          },
          deletionConfirmation: "Вы действительно хотите удалить?",
          deletionConfirmationText:
            "Удалится вся информация смен этой должности",
        },
        shifts: {
          title: "Смены",
          modal: {
            addTitle: "Добавление смены",
            editTitle: "Редактирование смены",
          },
          shiftName: "Смена",
          coefficient: "Коэффициент",
          actions: "Действия",
          deletionConfirmation: "Вы действительно хотите удалить?",
        },
        daysConfig: {
          title: "Настройка дней подитогов месяца",
          titleShort: "Дни подитогов",
          modal: {
            addTitle: "Добавление",
            editTitle: "Редактирование",
          },
          daysCount: "Кол-во дней",
          from: "От",
          to: "До",
          actions: "Действия",
          deletionConfirmation: "Вы действительно хотите удалить?",
          fullMonth: "Полный месяц",
        },
      },
      templateTypes: {
        title: "Типы шаблонов",
        table: {
          type: "Тип",
          actions: "Действия",
        },
        templates: {
          title: "Шаблон",
          add: "Добавить",
          deletionConfirmation: "Вы действительно хотите удалить?",
          table: {
            language: "Язык",
            actions: "Действия",
          },
          editModal: {
            title: "Редактирование шаблона",
            form: {
              language: {
                label: "Язык",
                placeholder: "Выберите язык",
              },
              content: {
                label: "Содержимое",
              },
            },
						restoreModal: {
							title: "Восстановление шаблона",
							form: {
								file: {
									label: "Файл",
									placeholder: "Выберите файл",
								}
							},
						},
          },
        },
      },
    },
    clients: {
      tabs: {
        clients: {
          title: "Клиенты",
          add: "Добавить",
          searchQuery: "Поиск по имени / тел.",
          deletionConfirmation: "Вы действительно хотите удалить?",
          addModal: {
            title: "Добавление нового клиента",
            isAllowed: {
              title: "Вы разрешаете загрузить фото?",
              yes: "да",
              no: "нет",
            },
            form: {
              fullName: {
                label: "ФИО",
                placeholder: "Введите полное имя",
              },
              telNumber: {
                label: "Tел. номер",
                placeholder: "Введите тел. номер",
              },
              citizenship: {
                label: "Гражданство",
                placeholder: "Выберите гражданство",
              },
            },
          },
          editModal: {
            title: "Редактирование клиента",
          },
          table: {
            id: "ID",
            fullName: "ФИО",
            telNumber: "Тел. номер",
            citizenship: "Гражданство",
            actions: "Действия",
          },
        },
        organizations: {
          title: "Организации",
          add: "Добавить",
          searchQuery: "Поиск по названию",
          deletionConfirmation: "Вы действительно хотите удалить?",
          addModal: {
            title: "Добавление новой организации",
            form: {
              title: {
                label: "Название",
                placeholder: "Введите название",
              },
              telNumber: {
                label: "Tел. номер",
                placeholder: "Введите тел. номер",
              },
            },
          },
          editModal: {
            title: "Редактирование организации",
          },
          table: {
            id: "ID",
            title: "Название",
            telNumber: "Тел. номер",
            actions: "Действия",
          },
        },
      },
    },
    userSheet: {
      oneWeek: "Неделя",
      oneMonth: "Месяц",
      threeMonths: "3 месяца",
      rate: "Ставка",
      shiftCount: "Кол-во смен",
      prepayment: "Аванс",
      bonus: "Бонус",
      penalty: "Штраф",
      debt: "Долг",
      salary: "Зарплата",
      totalPeriod: "Итого",
      totalMonth: "Итого за месяц",
      totalPaid: "Оплачено",
      living: "Проживающих",
      clientBreakfast: "Завтрак",
      conference: "Конференция",
      morningCoffeeBreak: "Кофе-брейк №1",
      lunchCoffeeBreak: "Кофе-брейк №2",
      lunchClient: "Обед",
      dinnerClient: "Ужин",
      breakfastUser: "Завтрак персонал",
      lunchUser: "Обед персонал",
      dinnerUser: "Ужин персонал",
      number: "№",
      fullName: "ФИО",
      position: "Должность",
      actions: {
        addingAdditionalCharge: "Добавление аванс/штраф/бонус/долг",
        generateFromPreviousMonth: "Сгенерировать с предыдущего месяца",
      },
      debtAlertText: 'Только для долгов в виде денег!\nЕсли в виде услуги или товара, вписать через кассу.',
      overnight: "Ночёвка",
      breakfast: "Завтрак",
      lunch: "Обед",
      dinner: "Ужин",
      paySalary: {
        payingSalary: "Выдача зарплаты",
        availableToPay: "Доступно к оплате",
      },
    },
    cashBox: {
      mainCashBox: 'Главная касса',
      shift: "Смена",
      cashBox: "Касса",
      time: "Время",
      startedAt: "Начало",
      closedAt: "Конец",
      source: "Источник",
      dateTime: 'Дата и время',
      num: "№",
      contractor: "Контрагент",
      searchContractor: "Поиск контрагента",
      notes: "Примечание",
      sum: "Сумма",
      currency: "Валюта",
      paymentType: "Вид платежа",
      beginningShiftBalance: "Остаток на начало смены",
      paid: "Оплатил",
      balance: "Остаток",
      inCurrency: "в валюте",
      total: "Итого",
      totalWithDebt: "Итого в системе",
      allTotal: "Общий итог",
      closedEmpty: "Касса закрылась пустой",
      shortage: "Недостача",
      surplus: "Излишки",
      fullName: "ФИО",
      clients: "Клиенты",
      staffs: "Сотрудники",
      organizations: "Организации",
      startShift: "Открыть смену",
      closedShift: "Закрытая смена",
      openedShift: "Открытая смена",
      income: {
        income: "Доход",
        debtShort: "Деб. задолженность",
        debtLong: "Дебиторская задолженность",
        repayDebt: "Погасить деб. задолженность",
      },
      expense: {
        expense: "Расход",
        debtShort: "Кред. задолженность",
        debtLong: "Кредиторская задолженность",
        repayDebt: "Погасить кред. задолженность",
      },
      noOpenShift: "Нет открытой смены",
      startCashBoxModal: {
        title: "Начало новой смены",
        cashBox: "Кассовый аппарат",
        startPaymentTitle: "Деньги на начало смены",
        leftPaymentTitle: 'Остаток с предыдущей смены',
        payment: "Оплата",
        total: 'Итого',
        alertDesc: 'Добавленная сумма и остаток с предыдущей смены одинаковых валют и типов оплаты будут суммироваться'
      },
      sendToCashBoxModal: {
        title: 'Отправка денег в кассу',
        send: 'Отправка денег в кассу',
        cashBox: "Открытый кассовый аппарат",
        payment: "Оплата",
      },
      closeTransferCashBoxModal: {
        closeTitle: "Закрытие смены",
        transferTitle: "Приём-передача смены",
        payment: "Оплата",
        transferAmount: "Сумма передачи",
        factAmount: "Фактически",
        inSystem: "В системе",
        inFact: "По факту",
        difference: "Разница",
        noShortagesAndSurpluses: "Нет недостач и излишек",
        shortageReason: "Причина недостачи",
        surplusReason: "Причина излишки",
        summarizeAmounts: "Подсчитывание денег",
        shortagesAndSurpluses: "Недостачи и излишки",
        transferShift: "Приём смены",
        takingSideData: "Данные принимающей стороны",
      },
      collectionShiftModal: {
        title: 'Инкассация кассы',
        payment: "Оплата",
        availableSum: 'Доступная сумма',
        collectionSum: 'Сумма изъятия',
        collectorData: "Данные инкассатора",
      }
    },
    profile: {
      title: "Профиль",
      form: {
        fullName: {
          label: "ФИО",
        },
        email: {
          label: "Email",
        },
        telNumber: {
          label: "Тел. номер",
        },
        userGroup: {
          label: "Должность",
        },
        createdAt: {
          label: "Дата регистрации",
        },
      },
    },
    todo: {
      tasks: 'Задания',
      cleaning: 'Уборка',
      actual: 'Актуальные',
      done: 'Выполненные',
      checkList: 'Чек лист',
      roomCleaningList: 'Список номеров для уборки',
      modal: {
        addTitle: 'Добавление задания',
        editTitle: 'Редактирование задания',
        executor: 'Исполнитель',
        deadline: 'Срок',
        priority: 'Приоритет',
        description: 'Описание',
        cleaner: 'Клинер',
        number: 'Номер',
        cleaningType: 'Уборка',
        notes: 'Примечание',
        cleaningDate: 'Дата уборки'
      },
      priorities: {
        LOW: 'Низкий',
        MEDIUM: 'Средний',
        HIGH: 'Высокий',
        ASAP: 'Срочно'
      },
      cleaningStatuses: {
        CLEAN: 'Чистый',
        DIRTY: 'Грязный'
      },
      cleaningTypes: {
        CHECK: 'Проверка',
        WET: 'Влажная',
        DAILY: 'Текущая',
        EXPRESS: 'Экспресс',
        FULL: 'Полная'
      },
      statuses: {
        CREATED: 'Не начато',
        IN_PROCESS: 'В процессе',
        DONE: 'Готово'
      },
      cleanerFullName: 'ФИО клинера'
    },
    accessDenied: {
      title: "Доступ запрещен",
    },
    pageNotFound: {
      title: "Страница не найдена",
    },
  },
  exchange: {
    exchangeTo: "Обменять на",
    rate: "Курс",
    impossibleToExchange: "Обмен на данную валюту невозможна.",
    toCashBox: "на кассу",
    toUserSheet: "в табель",
  },
  infographics: {
    hotelLoad: "Загруженность отеля",
    choosePeriod: "Выбрать пероид",
    today: "Сегодня",
    last7Days: "Последние 7 дней",
    lastMonth: "Последний месяц",
    load: "Загруженность",
    loadShort: "Загруж.",
    totalSum: "Общая сумма",
    allRooms: "Всего номеров",
    busyRooms: "Всего заняты",
    salesFor: "Продажи на",
    source: "Источник",
    debt: "Задолженность",
    debtShort: "Задолж.",
    quantity: 'Количество'
  },
  payment: {
    type: {
      CASH: {
        long: "Наличные",
        short: "Наличные",
      },
      E_WALLET: {
        long: "Электронные кошельки",
        short: "Эл. кошельки",
      },
      BANK_CARD: {
        long: "Банковская карта",
        short: "Банк. карта",
      },
      BANK_TRANSFER: {
        long: "Банковский перевод",
        short: "Банк. перевод",
      },
      OTHER: {
        long: "Другое",
        short: "Другое",
      },
    },
    currency: {
      ALL: "Албанский лек",
      AFN: "Афганистанский афгани",
      ARS: "Аргентинское песо",
      AWG: "Арубанский флорин",
      AUD: "Австралийский доллар",
      AZN: "Азербайджанский манат",
      BSD: "Багамский доллар",
      BBD: "Барбадосский доллар",
      BYN: "Белорусский рубль",
      BZD: "Белизский доллар",
      BMD: "Бермудский доллар",
      BOB: "Боливийский боливиано",
      BAM: "Конвертируемая марка Боснии и Герцеговины",
      BWP: "Ботсванская пула",
      BGN: "Болгарский лев",
      BRL: "Бразильский реал",
      BND: "Брунейский доллар",
      KHR: "Камбоджийский риель",
      CAD: "Канадский доллар",
      KYD: "Доллар Каймановых островов",
      CLP: "Чилийское песо",
      CNY: "Китайский юань",
      COP: "Колумбийское песо",
      CRC: "Костариканский колон",
      HRK: "Хорватская куна",
      CUP: "Кубинское песо",
      CZK: "Чешская крона",
      DKK: "Датская крона",
      DOP: "Доминиканское песо",
      XCD: "Восточно-карибский доллар",
      EGP: "Египетский фунт",
      SVC: "Сальвадорский колон",
      EUR: "Евро",
      FKP: "Фунт Фолклендских островов",
      FJD: "Доллар Фиджи",
      GHS: "Ганский седи",
      GIP: "Гибралтарский фунт",
      GTQ: "Гватемальский кетсаль",
      GGP: "Гернсийский фунт",
      GYD: "Гайанский доллар",
      HNL: "Гондурасская лемпира",
      HKD: "Гонконгский доллар",
      HUF: "Венгерский форинт",
      ISK: "Исландская крона",
      INR: "Индийская рупия",
      IDR: "Индонезийская рупия",
      IRR: "Иранский риал",
      IMP: "Фунт острова Мэн",
      ILS: "Израильский шекель",
      JMD: "Ямайский доллар",
      JPY: "Японская иена",
      JEP: "Фунт Джерси",
      KZT: "Казахстанский тенге",
      KPW: "Северокорейская вона",
      KRW: "Южнокорейская вона",
      KGS: "Кыргызский сом",
      LAK: "Лаосский кип",
      LBP: "Ливанский фунт",
      LRD: "Либерийский доллар",
      MKD: "Македонский денар",
      MYR: "Малайзийский ринггит",
      MUR: "Маврикийская рупия",
      MXN: "Мексиканское песо",
      MNT: "Монгольский тугрик",
      MAD: "Марокканский дирхам",
      MZN: "Мозамбикский метикал",
      NAD: "Намибийский доллар",
      NPR: "Непальская рупия",
      ANG: "Нидерландский антильский гульден",
      NZD: "Новозеландский доллар",
      NIO: "Никарагуанская кордоба",
      NGN: "Нигерийская наира",
      NOK: "Норвежская крона",
      OMR: "Оманский риал",
      PKR: "Пакистанская рупия",
      PAB: "Панамский бальбоа",
      PYG: "Парагвайский гуарани",
      PEN: "Перуанский соль",
      PHP: "Филиппинское песо",
      PLN: "Польский злотый",
      QAR: "Катарский риал",
      RON: "Румынский лей",
      RUB: "Российский рубль",
      SHP: "Фунт острова Святой Елены",
      SAR: "Саудовский риял",
      RSD: "Сербский динар",
      SCR: "Сейшельская рупия",
      SGD: "Сингапурский доллар",
      SBD: "Доллар Соломоновых островов",
      SOS: "Сомалийский шиллинг",
      ZAR: "Южноафриканский рэнд",
      LKR: "Шри-Ланкийская рупия",
      SEK: "Шведская крона",
      CHF: "Швейцарский франк",
      SRD: "Суринамский доллар",
      SYP: "Сирийский фунт",
      TWD: "Новый тайваньский доллар",
      THB: "Таиландский бат",
      TTD: "Доллар Тринидада и Тобаго",
      TRY: "Турецкая лира",
      TVD: "Доллар Тувалу",
      UAH: "Украинская гривна",
      AED: "Дирхам ОАЭ",
      GBP: "Британский фунт стерлингов",
      USD: "Доллар США",
      UYU: "Уругвайское песо",
      UZS: "Узбекский сум",
      VEF: "Венесуэльский боливар",
      VND: "Вьетнамский донг",
      YER: "Йеменский риал",
      ZWD: "Доллар Зимбабве",
    },
  },
  sources: {
    categories: {
      USER_SHEET: "Табель сотрудников",
      CASH_BOX_DEBT: "Задолженность",
      BOOKING: "Бронирование",
      CASH_BOX_REFILL: 'Пополнение из кассы',
      CASH_BOX_FILL: 'Пополнение кассы',
      CASH_BOX_COLLECTION: 'Инкассация кассы',
      CASH_BOX_TRANSFER: 'Перевод в кассу',
      OTHER: 'Другое'
    },
    DEBT_REFUND: "Возврат долга",
    PREPAYMENT_REFUND: "Возврат аванса",
    SALARY: "Зарплата",
    DEBT: "Долг",
    PREPAYMENT: "Аванс",
    REFUND: "Возврат средств",
    BOOKING_PAYMENT: "За номер",
    BOOKING_PAYMENT_REFUND: "Возврат средств",
    FROM_MAIN_CASH_BOX: 'Из главной кассы',
    FROM_OUTSOURCE: 'Из внешних источников',
    TO_MAIN_CASH_BOX: 'В главную кассу'
  },
  reports: {
    reports: "Отчеты",
    periodSorting: "Сортировка за период",
    last7Days: "последние 7 дней",
    lastMonth: "последний месяц",
    last3Months: "последние 3 месяца",
    reportFilter: "Включить в отчет:",
    userFilter: "Фильтр по персоналу:",
    all: "Всё",
    allUsers: "Весь персонал",
    averageRate: "Ср. ставка на 1 смену",
    shiftCount: "Количество смен",
    salary: "Оклад",
    prepayment: "Авансы",
    bonus: "Бонусы",
    penalty: "Штрафы",
    debt: "Долги",
    food: "Питание",
    totalSalary: "Общая ЗП",
    paidSalary: "Оплаченная ЗП",
    table: {
      number: "№",
      date: 'Дата',
      fullName: "ФИО",
      userGroupAlt: "Должность",
      averageRate: "Ср. ставка",
      shiftCount: "Кол-во смен",
      salary: "Оклад",
      prepayment: "Аванс",
      bonus: "Бонус",
      penalty: "Штраф",
      debt: "Долги",
      breakfast: "Завтрак",
      lunch: "Обед",
      dinner: "Ужин",
      totalSalary: "Общая ЗП",
      paidSalary: "Оплачено",
      source: 'Источник',
      contractor: 'Контрагент',
      amount: 'Сумма',
      paymentType: 'Тип оплаты',
      notes: 'Примечание',
      serviceGroup: 'Группа услуг',
      title: 'Название',
      count: 'Количество',
      payments: 'Платежи',
      services: 'Услуги',
      incomeInCurrency: 'Приход в валюте ({currency})',
      expenseInCurrency: 'Расход в валюте ({currency})',
      incomeTotal: 'Приход итого',
      expenseTotal: 'Расход итого',
      profit: 'Прибыль',
      cashBoxDebt: 'Дебиторская задолженность',
      total: 'Итого',
      byPaymentType: 'По способам оплат',
      byCurrency: 'По разным валютам',
      incomeBySource: 'Приход по источникам'
    },
    reportType: "Вид отчета",
    period: "Период",
    filter: "Фильтр",
    total: "Итого",
    incomeFilter: 'Фильтр по доходу',
    expenseFilter: 'Фильтр по расходу',
    income: 'Доход',
    expense: 'Расход',
    pnl: 'ОПУ',
    finReport: 'Фин. отчет',
    totalByPaymentGroup: 'Итого по группе "Платежи"',
    totalByServiceGroup: 'Итого по группе "Услуги"',
    pnlTitle: 'Доход и расход (P&L)',
    hotelLoad: "Загруженность отеля"
  },
  userGroups: {
    manager: "Менеджер",
    waiter: "Официант",
    worker: "Работник",
    admin: "Администратор",
    maid: "Горничная",
    pastryChef: "Кондитер",
    technician: "Техничка",
    cleaner: 'Клинер',
    dishwasher: "Посудомойщица",
    cashier: 'Кассир',
    cook: "Повар",
    laundress: "Прачка",
    reception: "Ресепшн",
    security: "Служба безопасности",
    electrician: "Электрик",
    other: "Другое",
  },
  templatesTypes: {
    BOOKING_CONFIRMATION: "Подтверждение брони",
    BOOKING_INVOICE: "Счёт фактура",
    BOOKING_INVOICE_FOR_PAYMENT: "Счет к оплате",
    TODO_CLEANING_CHECK_LIST: "Чек-лист уборки"
  },
  bookingStatuses: {
    CANCEL: "Отмена",
    CREATE: "Создано",
    CHECK_IN: "Заезд",
    CHECK_OUT: "Выезд",
  },
  $vuetify: {
    badge: "знак",
    close: "Закрыть",
    dataIterator: {
      noResultsText: "Не найдено подходящих записей",
      loadingText: "Запись загружается...",
    },
    dataTable: {
      itemsPerPageText: "Строк на странице:",
      ariaLabel: {
        sortDescending: "Упорядочено по убыванию.",
        sortAscending: "Упорядочено по возрастанию.",
        sortNone: "Не упорядочено.",
        activateNone: "Активируйте, чтобы убрать сортировку.",
        activateDescending: "Активируйте для упорядочивания убыванию.",
        activateAscending: "Активируйте для упорядочивания по возрастанию.",
      },
      sortBy: "Сортировать по",
    },
    dataFooter: {
      itemsPerPageText: "Записей на странице:",
      itemsPerPageAll: "Все",
      nextPage: "Следующая страница",
      prevPage: "Предыдущая страница",
      firstPage: "Первая страница",
      lastPage: "Последняя страница",
      pageText: "{0}-{1} из {2}",
    },
    datePicker: {
      itemsSelected: "{0} выбран",
      nextMonthAriaLabel: "Следующий месяц",
      nextYearAriaLabel: "Следующий год",
      prevMonthAriaLabel: "Прошлый месяц",
      prevYearAriaLabel: "Предыдущий год",
    },
    noDataText: "Отсутствуют данные",
    carousel: {
      prev: "Предыдущий слайд",
      next: "Следующий слайд",
      ariaLabel: {
        delimiter: "Слайд {0} из {1}",
      },
    },
    calendar: {
      moreEvents: "Еще {0}",
    },
    input: {
      clear: "Очистить {0}",
      prependAction: "{0} добавленное действие",
      appendAction: "{0} добавленное действие",
    },
    fileInput: {
      counter: "Файлов: {0}",
      counterSize: "Файлов: {0} (всего {1})",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Навигация по страницам",
        next: "Следующая страница",
        previous: "Предыдущая страница",
        page: "Перейти на страницу {0}",
        currentPage: "Текущая страница, Страница {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Рейтинг {0} из {1}",
      },
    },
    loading: "Загрузка...",
  },
  formLabels: {
    from: "От",
    to: "До",
    sum: "Сумма",
    currency: "Валюта",
    paymentType: "Тип оплаты",
    notes: "Примечание",
    enterComment: "Введите комментарий",
    date: "Дата",
    searchByTitle: "Поиск по названию",
    refund: "Возврат",
    cashBox: "Кассовый аппарат",
    title: "Название",
  },
  table: {
    title: "Название",
    actions: "Действия",
    type: "Тип",
    price: "Стоимость",
    category: "Категория",
    amount: 'Сумма',
    paymentType: 'Тип оплаты',
    currency: 'Валюта',
    num: '№',
    supervisor: 'Супервайзор',
    executor: 'Исполнитель',
    where: 'Где',
    description: 'Описание',
    createdAt: 'Создано',
    deadline: 'Срок',
    status: 'Статус',
    priority: 'Приоритет',
    number: 'Номер',
    numberType: 'Тип номера',
    cleaning: 'Уборка',
    checkIn: 'Заезд',
    checkOut: 'Выезд',
    guestFullName: 'ФИО гостя',
    cleanerFullName: 'ФИО клинера',
    adults: 'Взрослые',
    children: 'Дети',
    bookingStatus: 'Статус брони',
    numberStatus: 'Статус номера',
    notes: 'Примечание'
  },
  form: {
    fromDate: {
      label: "Дата от",
      placeholder: "Выберите дату",
    },
    toDate: {
      label: "Дата до",
      placeholder: "Выберите дату",
    },
  },
  buttons: {
    disclose: "Раскрыть",
    close: "Закрыть",
    reset: "Сбросить",
    form: "Сформировать",
    remove: "Удалить",
    save: "Сохранить",
    cancel: "Отмена",
    add: "Добавить",
    back: "Назад",
    edit: "Редактировать",
    view: "Посмотреть",
    movement: "Переезд",
    checkIn: "Заезд",
    checkOut: "Выезд",
    payment: "Оплата",
    invoiceForPayment: "Счёт к оплате",
    invoice: "Счёт фактура",
    confirmationInvoice: "Подтверждение брони",
    addPayment: "Добавить оплату",
    closeShift: "Закрыть смену",
    sendToCashBox: "Перевод в кассу",
    getShift: "Принять смену",
    transferShift: "Приём-передача",
    collectionShift: "Инкассация",
    submit: "Подтвердить",
    send: 'Отправить',
    openShift: 'Открыть смену',
    collect: 'Изъять'
  },
};
