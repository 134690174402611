import { apolloClient } from "@/plugins/apollo";
import { cyrb53 } from "@/utils/string";

const cache = {};
export const handleGqlResponseBody = async (cb) => {
  // const query = async (options) => {
  //   const result = (cache[cyrb53(JSON.stringify(options))] = {
  //     result,
  //   });
  //
  //   return {
  //     result: await apolloClient.query({ ...options, fetchPolicy: "no-cache" }),
  //   };
  // };

  const callbackResponse = cb({
    query: (options) => {
      return {
        options,
        query: apolloClient.query(options),
      };
    },
    mutation: (options) => {
      return {
        options,
        mutation: apolloClient.mutate(options),
      };
    },
  });

  if (callbackResponse.query) {
    const response = await callbackResponse.query;
    const data = Object.keys(response.data);
    if (data.length === 1) return response.data[data[0]];
    return data.map(d => response.data[d]);
  } else if (callbackResponse.mutation) {
    const response = await callbackResponse.mutation;
    const data = Object.keys(response.data);
    if (data.length === 1) return response.data[data[0]];
    return data.map(d => response.data[d]);
  }

  // try {
  //
  // } catch (e) {
  //   console.log("e", e);
  // }

  return null;
};
