import gql from 'graphql-tag'

export const RESET_USER_PASSWORD_MUTATION = gql`
  mutation ResetUserPasswordMutation ($email: String!){
    resetUserPassword(
      data: {
        email: $email
      }
    )
  }
`
