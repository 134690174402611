import Vue from "vue";
import { parsePhoneNumber } from "libphonenumber-js";

Vue.directive("parse-tel-number", {
  bind(el, binding, vnode) {
    el._inputHandler = function (e) {
      try {
        const parsedTelNumber = parsePhoneNumber(
          e.target.value
        ).formatInternational();
        vnode.context[binding.expression] = parsedTelNumber;
        vnode.componentInstance.$emit("input", parsedTelNumber);
      } catch (e) {
        // do nothing
      }
    };
    el.addEventListener("input", el._inputHandler);
  },
  update(el, binding, vnode) {
    // This will be called every time the value of the v-parse-phone binding changes.

    // Get the value of the v-parse-phone binding
    // Update the input element's value
    el.value = vnode.context[binding.expression];
  },
  unbind(el) {
    el.removeEventListener("input", el._inputHandler);
  },
});
