<template>
	<v-app>
		<v-toolbar height="52" class="toolbar" elevation="0">
			<v-container class="d-flex">
				<router-link to="/" class="text-decoration-none">
					<img v-if="logoUri" class="custom-logo" alt="logo" :src="logoUri"/>
					<logo-component
							v-else
							class="d-block"
							color="#5A81F9"
							:width="45"
							:height="35"
					/>
				</router-link>
				<v-spacer/>
				<v-toolbar-items>
					<div class="mt-3">
						<locale-change-component/>
					</div>
					<div class="ml-4">
						<v-row no-gutters class="fill-height" v-if="currentUser">
							<v-col class="mr-2" align-self="center">
								<v-avatar size="32">
									<v-img
											v-if="currentUser.profilePhotoX64"
											:src="currentUser.profilePhotoX64.fullUri"
											:key="`photo-${currentUser.id}-${currentUser.profilePhotoX64.id}`"
											lazy-src="@/assets/images/default_avatar.svg"
									/>
									<v-img
											v-else
											lazy-src="@/assets/images/default_avatar.svg"
											class="no-blur"
									/>
								</v-avatar>
							</v-col>
							<v-col align-self="center">
								<v-menu
										left
										bottom
										:offset-y="true"
										:offset-x="false"
										:position-y="10"
										transition="slide-y-transition"
										:open-on-hover="true"
										max-width="200"
										min-width="200"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
												large
												plain
												:ripple="false"
												class="no-uppercase pa-0 text--grey darken-4"
												v-bind="attrs"
												v-on="on"
												height="35"
												min-width="10"
										>
											<div
													class="account-title"
													v-if="$vuetify.breakpoint.smAndUp"
											>
												{{ currentUser.firstName }}
											</div>
											<v-icon
													right
													small
													class="toggleUpDown"
													:class="{ rotate: attrs['aria-expanded'] === 'true' }"
											>
												mdi-chevron-up
											</v-icon>
										</v-btn>
									</template>
									
									<v-list dense>
										<v-list-item to="/profile">
											<v-list-item-icon class="mr-2">
												<v-icon>mdi-account-circle</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>
													{{ $t('pages.common.profile') }}
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-list-item link @click="logout()">
											<v-list-item-icon class="mr-2">
												<v-icon>mdi-logout</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>
													{{ $t('pages.common.logout') }}
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-col>
						</v-row>
					</div>
				</v-toolbar-items>
			</v-container>
			<template v-slot:extension>
				<v-container class="pb-0">
					<v-tabs v-model="tab" centered>
						<template v-for="(item, i) in items">
							<v-tab
									v-if="haveOneOfPrivileges(item.privileges)"
									:key="`tab-${i}`"
									:value="item"
									class="no-uppercase"
									:to="item.to"
							>
								<v-icon class="mr-2" v-text="item.icon"/>
								{{ $t(`toolbar.tabs.${item.title}`) }}
							</v-tab>
						</template>
					</v-tabs>
				</v-container>
			</template>
		</v-toolbar>
		<v-container>
			<v-main>
				<slot/>
			</v-main>
		</v-container>
	</v-app>
</template>

<script>
import {mapGetters} from 'vuex';
import LogoComponent from '@/components/LogoComponent';
import LocaleChangeComponent from '@/components/LocaleChangeComponent.vue';
import {privilegeMixin} from '@/mixins/privilege.mixin';
import {TOP_BAR_TAB_ITEMS} from '@/utils/top-bar.util';

export default {
	components: {LocaleChangeComponent, LogoComponent},
	mixins: [
		privilegeMixin,
	],
	data() {
		return {
			buildVersion: process.env.VUE_APP_BUILD_VERSION,
			tab: null
		};
	},
	computed: {
		...mapGetters({
			currentUser: 'getCurrentUser',
			logoUri: 'getLogo'
		}),
		items: () => TOP_BAR_TAB_ITEMS
	},
	methods: {
		async logout() {
			await this.$showGqlError(async () => {
				await this.$store.dispatch('logoutUser');
				await this.$router.push({path: '/login'});
			});
		}
	}
};
</script>

<style scoped lang="scss">
.toolbar {
	display: contents;
	
	.custom-logo {
		margin-top: 10px;
		max-width: 45px;
		max-height: 45px;
	}
	
	.account-title {
		font-size: 1em;
		max-width: 150px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	
	.toggleUpDown {
		transition: transform 0.3s ease-in-out !important;
	}
	
	.toggleUpDown.rotate {
		transform: rotate(180deg);
	}
}

@media (max-width: 480px) {
	.d-block {
		width: 35px;
		height: 25px;
		margin-top: 18px;
	}
	
	.d-inline-block {
		font-size: 12px;
		
		span {
			font-size: 10px !important;
		}
	}
}
</style>
