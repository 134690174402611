import gql from 'graphql-tag'

export const GET_USER_SHIFT_PAGINATION_QUERY = gql`
  query GetUserShiftPagination (
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
  ) {
    getUserShiftPaginated (
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
      }
    ) {
      hasMore
      total
      items {
        alt
        coefficient
      }
    }
  }
`
