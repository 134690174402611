export function toZPad(value, zeros = 2) {
  return `${value}`.padStart(zeros, "0");
}

export function cutValue(value, to) {
  return `${value.substring(0, to)}...`;
}

export function toSnakeCase(value) {
  const regex = new RegExp(/(?=[A-Z])/, "g");
  if (!regex.test(value)) return value;
  const splitValue = value.split(regex);
  return splitValue.join("_").toLowerCase();
}

export const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};
