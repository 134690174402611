<template>
  <div>
      {{ $t('pages.pageNotFound.title') }}
  </div>
</template>

<script>

export default {}
</script>

<style scoped lang="scss">
.interacted-with {
  font-size: 14px;
}
</style>
