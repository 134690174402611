import gql from 'graphql-tag'

export const GET_CASH_BOX_DEBT_PAGINATION_QUERY = gql`
  query GetCashBoxDebtPagination (
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
    $search: String
    $type: CashBoxItemType!
  ) {
    getCashBoxDebtsPaginated (
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
        query: $search
        type: $type
      }
    ) {
      hasMore
      total
      items {
        id
        leftPayment {
          currencyTitle
          amount
        }
        paidPayment {
          id
          amount
          currencyTitle
        }
        item {
          notes
          user {
            id
            fullName
          }
          client {
            id
            fullName
          }
          organization {
            id
            title
          }
          source {
            title
            category {
              title
            }
          }
        }
        closed
        createdAt
      }
    }
  }
`
