const translateErrorMessage = (i18n, errorKey) => i18n.te(`errorMessages.${errorKey}`) ? i18n.t(`errorMessages.${errorKey}`) : errorKey

function translateGraphQLError (i18n, message) {
  return `${translateErrorMessage(i18n, message)}`;
}

export function gqlErrorToast (toast, i18n, gqlError, except = [], notShowToast = false, isExceptPartial = false) {
  const excluded = {}
  const handledErrors = []
  let hasError = false
  const handleErrors = (errors) => {
    for (let i = 0; i < errors.length; i++) {
      const e = errors[i]
      const exceptFound = except.find(message => isExceptPartial ? e.message.includes(message) : message === e.message)
      if (exceptFound) {
        excluded[exceptFound] = e
      } else {
        hasError = true
        const handledError = translateGraphQLError(i18n, e.extensions?.response?.message ?? e.message)
        handledErrors.push(handledError)
        if (!notShowToast) toast.error(handledError)
      }
    }
  }

  if (gqlError.networkError) {
    if (gqlError.networkError.result) handleErrors(gqlError.networkError.result.errors)
    else handleErrors([gqlError.networkError])
  } else if (gqlError.graphQLErrors) {
    handleErrors(gqlError.graphQLErrors)
  } else {
    toast.error(gqlError.message)
  }

  return {
    excluded,
    hasError,
    handledErrors
  }
}
