import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_SYSTEM_CONFIG_QUERY } from "@/graphql/queries/system-config/get-system-config.query";
import {
  getObjectFromLocalStorage,
  setObjectToLocalStorage,
} from "@/utils/local-storage.util";
import { PaymentUtil } from "@/utils/payment.util";

const systemConfig = {
  state: {
    systemConfigs: getObjectFromLocalStorage("systemConfigs"),
  },
  getters: {
    getLogo(state) {
      if (!state.systemConfigs) return null;
      return state.systemConfigs.common.logo;
    },
    getLanguages(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.common.languages;
    },
    getCitizenships(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.common.citizenships;
    },
    getCurrencies(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.common.currencies;
    },
    getAllCurrencies(state) {
      if (!state.systemConfigs) return {};
      return state.systemConfigs.common.allCurrencies;
    },
    getDefaultCurrency(state) {
      if (!state.systemConfigs) return null;
      return state.systemConfigs.common.mainCurrency;
    },
    getDefaultPaymentType(state) {
      if (!state.systemConfigs) return null;
      return state.systemConfigs.common.paymentTypes.find((x) => x.static);
    },
    getExchangeRateGroup(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.common.exchangeRateGroup;
    },
    getPaymentCategories(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.common.paymentCategories;
    },
    getPaymentTypesAutocomplete(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.common.paymentTypesAutocomplete;
    },
    getPaymentTypes(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.common.paymentTypes;
    },

    getRooms(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.booking.rooms;
    },
    getRoomTariffs(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.booking.roomTariffs;
    },
    getServices(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.booking.services;
    },
    getKitchenMenus(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.booking.kitchenMenus;
    },
    getDishes(state) {
      if (!state.systemConfigs) return [];
      return state.systemConfigs.booking.dishes;
    },
    getCheckInTime(state) {
      if (!state.systemConfigs) return null;
      return state.systemConfigs.booking.checkInTime;
    },
    getCheckOutTime(state) {
      if (!state.systemConfigs) return null;
      return state.systemConfigs.booking.checkOutTime;
    },
    getBookingEarlyCheckInPercentageFee(state) {
      if (!state.systemConfigs) return null;
      return state.systemConfigs.booking.earlyCheckInPercentageFee;
    },
    getBookingLateCheckOutPercentageFee(state) {
      if (!state.systemConfigs) return null;
      return state.systemConfigs.booking.lateCheckOutPercentageFee;
    },
    isSystemReady(state) {
      return !!state.systemConfigs;
    },
  },
  actions: {
    async loadSystemConfigs({ state }) {
      try {
        const configs = await handleGqlResponseBody(({ query }) => {
          return query({
            query: GET_SYSTEM_CONFIG_QUERY,
            fetchPolicy: "no-cache",
          });
        });

        PaymentUtil.mapExchangeRates(configs.common.exchangeRateGroup);

        const { actual, mapped, main } = PaymentUtil.mapAllCurrencies(
          configs.common.currencies
        );
        configs.common.currencies = actual;
        configs.common.allCurrencies = mapped;
        configs.common.mainCurrency = main;

        configs.common.paymentTypesAutocomplete =
          PaymentUtil.toPaymentTypesAutocompleteList(
            configs.common.paymentTypes
          );

        state.systemConfigs = configs;
        setObjectToLocalStorage("systemConfigs", configs);
      } catch (e) {
        // Catch
        console.log("Error loading system configs", e);
      }
    },
  },
};

export default systemConfig;
