const loading = {
  state: {
    isLoading: false
  },
  getters: {
    isLoading (state) {
      return state.isLoading
    }
  },
  mutations: {
    setLoading (state, { isLoading }) {
      state.isLoading = isLoading
    }
  },
  actions: {
    async setLoading ({ commit }, isLoading) {
      commit('setLoading', { isLoading })
    }
  }
}

export default loading
