import gql from 'graphql-tag'

export const GET_ROOM_TARIFFS_PAGINATION_QUERY = gql`
  query GetRoomTariffsPagination (
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
    $query: String
  ) {
    getRoomTariffs (
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
        query: $query
      }
    ) {
      hasMore
      total
      items {
          id
          title
          description
      }
    }
  }
`
