import gql from "graphql-tag";

export const GET_SYSTEM_CONFIG_QUERY = gql`
  query GetSystemConfig {
    getSystemConfig {
      common {
        logo
        currencies {
          isMain
          title
          symbol
          deletedAt
        }
        exchangeRateGroup {
          id
          fromDate
          defaultCurrencyTitle
          rates {
            id
            currencyTitle
            currency {
              title
              symbol
              isMain
            }
            value
          }
        }
        paymentTypes {
          id
          title
          category {
            id
            title
          }
          static
        }
        paymentCategories {
          id
          title
          static
        }
        languages {
          id
          icon
        }
        citizenships {
          id
          icon
        }
      }
      booking {
        rooms {
          id
          number
          title
          categories {
            id
            title
          }
          checkInOutType
        }
        roomTariffs {
          id
          title
          discounts {
            id
            type
            value
            onlyForRoom
            currencyTitle
          }
          prices {
            roomCategoryId
            value
            fromDate
            toDate
          }
        }
        services {
          id
          title
          deletedAt
          fromDate
          toDate
          toTime
          toTime
          prices {
            id
            value
            fromDate
            toDate
          }
        }
        kitchenMenus {
          id
          fromDate
          toDate
          fromTime
          toTime
          title
          dishes {
            dishId
            value
          }
          deletedAt
        }
        dishes {
          id
          title
          unit
          prices {
            id
            fromDate
            toDate
            value
          }
          fromDate
          toDate
          fromTime
          toTime
          deletedAt
        }
        checkInTime
        checkOutTime
        earlyCheckInPercentageFee
        lateCheckOutPercentageFee
      }
    }
  }
`;
