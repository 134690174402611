import { getFromLocalStorage, setToLocalStorage } from "@/utils/local-storage.util"

const currentUser = {
  state: {
    user: getFromLocalStorage('user') ? JSON.parse(getFromLocalStorage('user')) : null,
  },
  getters: {
    getCurrentUser (state) {
      return state.user
    }
  },
  mutations: {
    setCurrentUser (state, user) {
      setToLocalStorage('user', JSON.stringify(user))
      state.user = user
    },
    clearCurrentUser (state) {
      state.user = null
    }
  },
  actions: {

  }
}

export default currentUser
