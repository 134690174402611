<template>
    <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512" xmlns:xlink="http://www.w3.org/1999/xlink"
            :width="width"
            :height="height"
            :style="hasShadow ? 'filter: drop-shadow(0 0 4px rgb(0 0 0 / 1));' : ''"
            :fill="color"
    >
        <g>
            <path d="m480.7,130.8h-51.3v-75.9h51.3c11.3-7.10543e-15 20.4-9.8 20.4-21.9s-9.2-22-20.4-22h-449.2c-11.3,0-20.4,9.8-20.4,21.9s9.1,21.9 20.4,21.9h51.3v75.9h-51.3c-11.3,0-20.4,9.8-20.4,21.9v326.3c0,12.1 9.1,21.9 20.4,21.9h449.2c11.3,0 20.4-9.8 20.4-21.9v-326.2c5.68434e-14-12.1-9.2-21.9-20.4-21.9zm-357-75.9h264.8v75.9h-264.8v-75.9zm336.5,402.2h-408.3v-282.4h408.3v282.4z"/>
            <polygon points="92.6,319.4 132.8,319.4 132.8,375.9 143,375.9 143,255.9 132.8,255.9 132.8,305.2 92.6,305.2 92.6,255.9      82.4,255.9 82.4,375.9 92.6,375.9    "/>
            <path d="m174.3,369.8c5.7,5.5 12.2,8.2 19.5,8.2 6.7,0 13-2.5 18.8-7.4 5.8-4.9 10.3-12.2 13.4-21.8 3.2-9.6 4.7-20.5 4.7-32.7 0-12-1.5-22.7-4.5-32.2-3-9.5-7.4-16.8-13.1-22.1-5.7-5.3-12.2-7.9-19.3-7.9-10.9,0-19.8,5.6-26.7,16.9-6.9,11.3-10.3,26.9-10.3,46.8 0,10.5 1.5,20.5 4.4,30 3.1,9.3 7.4,16.7 13.1,22.2zm1-90.8c5.2-7.7 11.4-11.6 18.7-11.6 5.1,0 9.7,2 13.8,6 4.1,4 7.2,9.7 9.3,17 2.1,7.3 3.2,15.8 3.2,25.6 0,15.4-2.5,27.4-7.5,35.8-5,8.4-11.3,12.6-19,12.6-7.6,0-13.8-4.2-18.8-12.5-5-8.3-7.5-19.7-7.5-34.2 0-18 2.6-30.9 7.8-38.7z"/>
            <polygon points="263.6,375.9 273.9,375.9 273.9,270.1 299.4,270.1 299.4,255.9 238.2,255.9 238.2,270.1 263.6,270.1    "/>
            <polygon points="367.8,361.8 320.3,361.8 320.3,320.9 363.1,320.9 363.1,306.8 320.3,306.8 320.3,270.1 366,270.1 366,255.9      310.1,255.9 310.1,375.9 367.8,375.9    "/>
            <polygon points="429.8,361.8 391.7,361.8 391.7,255.9 381.5,255.9 381.5,375.9 429.8,375.9    "/>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'LogoComponent',
  props: {
    hasShadow: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 44
    },
    height: {
      type: Number,
      default: 32
    }
  }
}
</script>
