<template>
    <div>
        {{ $t('pages.logout.loggingOut') }}
    </div>
</template>

<script>

export default {
    async mounted() {
        await this.$showGqlError(async () => {
            await this.$store.dispatch('logoutUser')
            await this.$router.push({ path: '/login' })
        })
    }
}
</script>
