module.exports = {
  title: "Eco-Smart",
  validators: {
    required: "This field is required",
    passwordMustMatch: "Passwords must match",
    invalidEmail: "Invalid email address",
    maxLength: "Field must not exceed {length} characters",
    minValue: "Minimum allowed value is {value}",
    minArrayValue: "Select at least {value} element | {value} elements",
    minValueExcluded: "Value must be greater than {value}",
    maxValue: "Maximum allowed value is {value}",
    selectAtLeastOne: "Select at least one option",
    firstName: "First name is required",
    lastName: "Last name is required",
    notAvailableAtThisDate: "Not available on the specified date ({date})",
    priceIsNotSetAtThisDate: "Price is not set for the specified date ({date})",
    requiredPriceDefinition: "Price is not defined in the system",
    cashBoxAmountValidator:
      'Values in the "Actual" column must be filled and not negative',
  },
  errorMessages: {
    "Failed to fetch":
      "You have connection issues, please check your internet and try again",
    USER_EMAIL_ALREADY_CREATED:
      "User with this email has already been registered",
    VALUE_MUST_NOT_BE_0: "The amount must be greater than 0",
  },
  toolbar: {
    tabs: {
      checkerboard: "Checkerboard",
      cashbox: "Cash-box",
      mainCashbox: "Main cash-box",
      userSheet: "Timesheet",
      todo: "Task",
      clients: "Clients",
      configuration: "Administration",
    },
  },
  date: {
    months: {
      short: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      full: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    },
    days: {
      short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    },
    times: {
      days: {
        short: "d.",
      },
      hours: {
        short: "h.",
      },
      minutes: {
        short: "min.",
      },
      seconds: {
        short: "sec.",
      },
    },
  },
  pages: {
    common: {
      profile: "Profile",
      logout: "Logout",
      loading: "Loading",
      save: "Save",
      noData: "No data",
      actions: "Actions",
      room: {
        status: {
          AVAILABLE: "Available",
          REPAIRING: "Under repair",
        },
        checkInOutType: {
          NOT_DEFINED: "Not defined",
          FROM_SYSTEM: "From system",
          HOURLY: "Hourly",
        },
        tariff: {
          service: {
            type: {
              ONE_TIME: "One-time",
              DAILY: "Daily",
            },
          },
          discount: {
            type: {
              PERCENTAGE: "Percentage",
              CUSTOM_PRICE: "Flexible price",
            },
          },
        },
      },
      booking: {
        priceTypes: {
          ROOM: "Room",
          ROOM_HOURLY: "Room (hourly)",
          EARLY_CHECKIN: "Early check-in",
          LATE_CHECKOUT: "Late checkout",
          ADDITIONAL_SERVICE: "Additional services",
          KITCHEN_MENU: "Food: menu",
          DISH: "Food: dish",
        },
      },
      kitchen: {
        ingredientUnit: {
          KG: {
            title: "Kilogram",
            shortTitle: "kg.",
          },
          LITER: {
            title: "Liter",
            shortTitle: "l.",
          },
          PIECE: {
            title: "Piece",
            shortTitle: "pc.",
          },
          PORTION: {
            title: "Portion",
            shortTitle: "portion",
          },
        },
      },
      cancel: "Cancel",
      delete: "Delete",
      confirmDialog: "Are you sure you want to proceed with this operation?",
      deletionConfirmation: "Are you sure you want to delete?",
      oops: "Oops",
      reload: "Reload",
      countryNames: {
        AU: "Australia",
        AT: "Austria",
        AZ: "Azerbaijan",
        AL: "Albania",
        DZ: "Algeria",
        AI: "Anguilla (GB)",
        AO: "Angola",
        AD: "Andorra",
        AQ: "Antarctica",
        AG: "Antigua and Barbuda",
        AN: "Netherlands Antilles (NL)",
        AR: "Argentina",
        AM: "Armenia",
        AW: "Aruba",
        AF: "Afghanistan",
        AX: "Aland Islands",
        BS: "Bahamas",
        BD: "Bangladesh",
        BB: "Barbados",
        BH: "Bahrain",
        BY: "Belarus",
        BZ: "Belize",
        BE: "Belgium",
        BJ: "Benin",
        BM: "Bermuda",
        BV: "Bouvet Island (NO)",
        BG: "Bulgaria",
        BO: "Bolivia",
        BA: "Bosnia and Herzegovina",
        BW: "Botswana",
        BR: "Brazil",
        BN: "Brunei Darussalam",
        BF: "Burkina Faso",
        BI: "Burundi",
        BT: "Bhutan",
        VU: "Vanuatu",
        VA: "Vatican",
        GB: "United Kingdom",
        HU: "Hungary",
        VE: "Venezuela",
        VG: "British Virgin Islands (GB)",
        VI: "U.S. Virgin Islands (US)",
        AS: "American Samoa (US)",
        TP: "East Timor",
        VN: "Vietnam",
        GA: "Gabon",
        HT: "Haiti",
        GY: "Guyana",
        GM: "Gambia",
        GH: "Ghana",
        GP: "Guadeloupe",
        GG: "Guernsey",
        GT: "Guatemala",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        DE: "Germany",
        GI: "Gibraltar",
        HN: "Honduras",
        HK: "Hong Kong (CN)",
        GD: "Grenada",
        GL: "Greenland (DK)",
        GR: "Greece",
        GE: "Georgia",
        GU: "Guam",
        DK: "Denmark",
        CD: "Democratic Republic of the Congo",
        JE: "Jersey",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominican Republic",
        EG: "Egypt",
        ZM: "Zambia",
        EH: "Western Sahara",
        ZW: "Zimbabwe",
        IL: "Israel",
        IN: "India",
        ID: "Indonesia",
        JO: "Jordan",
        IQ: "Iraq",
        IR: "Iran",
        IE: "Ireland",
        IS: "Iceland",
        ES: "Spain",
        IT: "Italy",
        YE: "Yemen",
        CV: "Cape Verde",
        KZ: "Kazakhstan",
        KY: "Cayman Islands (GB)",
        KH: "Cambodia",
        CM: "Cameroon",
        CA: "Canada",
        QA: "Qatar",
        KE: "Kenya",
        CY: "Cyprus",
        KG: "Kyrgyzstan",
        KI: "Kiribati",
        CN: "China",
        CC: "Cocos (Keeling) Islands (AU)",
        CO: "Colombia",
        KM: "Comoros Islands",
        CG: "Congo",
        CR: "Costa Rica",
        CI: "Ivory Coast",
        CU: "Cuba",
        KW: "Kuwait",
        CK: "Cook Islands (NZ)",
        LA: "Laos",
        LV: "Latvia",
        LS: "Lesotho",
        LR: "Liberia",
        LB: "Lebanon",
        LY: "Libya",
        LT: "Lithuania",
        LI: "Liechtenstein",
        LU: "Luxembourg",
        MU: "Mauritius",
        MR: "Mauritania",
        MG: "Madagascar",
        YT: "Mayotte Island (KM)",
        MO: "Macau (PT)",
        MK: "North Macedonia",
        MW: "Malawi",
        MF: "Saint Martin",
        MY: "Malaysia",
        ML: "Mali",
        MV: "Maldives",
        MT: "Malta",
        MA: "Morocco",
        MQ: "Martinique",
        MH: "Marshall Islands",
        MX: "Mexico",
        FM: "Micronesia (US)",
        MZ: "Mozambique",
        MD: "Moldova",
        MC: "Monaco",
        MN: "Mongolia",
        MS: "Montserrat Island (GB)",
        MM: "Myanmar",
        NA: "Namibia",
        NR: "Nauru",
        NP: "Nepal",
        NE: "Niger",
        NG: "Nigeria",
        NL: "Netherlands",
        NI: "Nicaragua",
        NU: "Niue Island (NZ)",
        NZ: "New Zealand",
        NC: "New Caledonia Island (FR)",
        NO: "Norway",
        NF: "Norfolk Island (AU)",
        AE: "United Arab Emirates",
        OM: "Oman",
        IM: "Isle of Man",
        PK: "Pakistan",
        PW: "Palau (US)",
        PS: "Palestinian Territories",
        PA: "Panama",
        PG: "Papua New Guinea",
        PY: "Paraguay",
        PE: "Peru",
        PN: "Pitcairn Islands (GB)",
        PL: "Poland",
        PT: "Portugal",
        PR: "Puerto Rico (US)",
        RE: "Reunion Island (FR)",
        CX: "Christmas Island (AU)",
        RU: "Russia",
        RW: "Rwanda",
        RO: "Romania",
        SV: "El Salvador",
        WS: "Samoa",
        SM: "San Marino",
        ST: "Sao Tome and Principe",
        SA: "Saudi Arabia",
        SZ: "Eswatini",
        SJ: "Svalbard and Jan Mayen Islands (NO)",
        SH: "Saint Helena Island (GB)",
        KP: "North Korea (DPRK)",
        MP: "Northern Mariana Islands",
        SC: "Seychelles",
        VC: "Saint Vincent and the Grenadines",
        PM: "Saint Pierre and Miquelon (FR)",
        SN: "Senegal",
        KN: "Saint Kitts and Nevis",
        LC: "Saint Lucia",
        BL: "Saint Barthelemy",
        RS: "Serbia",
        SG: "Singapore",
        SY: "Syria",
        SK: "Slovakia",
        SI: "Slovenia",
        US: "United States of America",
        SB: "Solomon Islands",
        SO: "Somalia",
        SD: "Sudan",
        SR: "Suriname",
        SL: "Sierra Leone",
        SS: "South Sudan",
        TJ: "Tajikistan",
        TH: "Thailand",
        TW: "Taiwan",
        TZ: "Tanzania",
        TL: "Timor-Leste",
        TC: "Turks and Caicos Islands (GB)",
        TG: "Togo",
        TK: "Tokelau Islands (NZ)",
        TO: "Tonga",
        TT: "Trinidad and Tobago",
        TV: "Tuvalu",
        TN: "Tunisia",
        TM: "Turkmenistan",
        TR: "Turkey",
        UG: "Uganda",
        UZ: "Uzbekistan",
        UA: "Ukraine",
        WF: "Wallis and Futuna Islands (FR)",
        UY: "Uruguay",
        FO: "Faroe Islands (DK)",
        FJ: "Fiji",
        PH: "Philippines",
        FI: "Finland",
        FK: "Falkland Islands (Malvinas) (GB/AR)",
        FR: "France",
        GF: "French Guiana (FR)",
        PF: "French Polynesia",
        HM: "Heard Island and McDonald Islands (AU)",
        HR: "Croatia",
        CF: "Central African Republic",
        TD: "Chad",
        ME: "Montenegro",
        CZ: "Czech Republic",
        CL: "Chile",
        CH: "Switzerland",
        SE: "Sweden",
        LK: "Sri Lanka",
        EC: "Ecuador",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        EE: "Estonia",
        ET: "Ethiopia",
        YU: "Yugoslavia",
        ZA: "South Africa",
        GS: "South Georgia and the South Sandwich Islands",
        KR: "South Korea (Republic of Korea)",
        JM: "Jamaica",
        JP: "Japan",
        TF: "French Southern Territories (FR)",
        IO: "British Indian Ocean Territory (GB)",
        UM: "United States Minor Outlying Islands (US)",
      },
      languageNames: {
        EN: "English",
        ES: "Spanish",
        ZH: "Mandarin Chinese",
        HI: "Hindi",
        AR: "Arabic",
        FR: "French",
        RU: "Russian",
        BN: "Bengali",
        PT: "Portuguese",
        UR: "Urdu",
        ID: "Indonesian",
        DE: "German",
        JA: "Japanese",
        SW: "Swahili",
        KO: "Korean",
        TR: "Turkish",
        IT: "Italian",
        NL: "Dutch",
        PL: "Polish",
        VI: "Vietnamese",
        TA: "Tamil",
        TE: "Telugu",
        MR: "Marathi",
        TH: "Thai",
        PA: "Punjabi",
        ML: "Malayalam",
        YO: "Yoruba",
        TL: "Tagalog",
        SD: "Sindhi",
        FA: "Farsi (Persian)",
        SV: "Swedish",
        EL: "Greek",
        CS: "Czech",
        HU: "Hungarian",
        HE: "Hebrew",
        FI: "Finnish",
        DA: "Danish",
        NO: "Norwegian",
        IS: "Icelandic",
        SK: "Slovak",
        SL: "Slovenian",
        HR: "Croatian",
        BS: "Bosnian",
        SR: "Serbian",
        ME: "Montenegrin",
        SQ: "Albanian",
        MK: "Macedonian",
        BG: "Bulgarian",
        RO: "Romanian",
        MO: "Moldovan",
        UK: "Ukrainian",
        BE: "Belarusian",
        LV: "Latvian",
        LT: "Lithuanian",
        ET: "Estonian",
        KA: "Georgian",
        HY: "Armenian",
        AZ: "Azerbaijani",
        KK: "Kazakh",
        KY: "Kyrgyz",
        TK: "Turkmen",
        UZ: "Uzbek",
        TG: "Tajik",
        PS: "Pashto",
        "FA-AF": "Dari",
        KU: "Kurdish",
        BAL: "Balochi",
        BO: "Tibetan",
        NE: "Nepali",
        SI: "Sinhala",
        MY: "Burmese",
        KM: "Khmer",
        LO: "Lao",
        MS: "Malay",
        JV: "Javanese",
        SU: "Sundanese",
        MAD: "Madurese",
        HA: "Hausa",
        IG: "Igbo",
        KN: "Kannada",
        MG: "Malagasy",
        FJ: "Fijian",
        TO: "Tongan",
        SM: "Samoan",
        MI: "Maori",
      },
    },
    login: {
      title: "Login",
      yourEmail: "Your email",
      password: "Password",
      forgotYourPassword: "Forgot your password?",
      submit: "Login",
    },
    passwordRecovery: {
      title: "Password Recovery",
      enterYourEmail: "Enter your email",
      rememberYourPassword: "Remember your password?",
      submit: "Recover",
      instructionDescription:
        "Password recovery instructions have been sent. Please check your email",
      goToLoginScreen: "Go to login screen",
    },
    passwordRecoveryConfirmation: {
      title: "Password Recovery Confirmation",
      invalidResetTokenDescription:
        "Invalid token. Please try to recover your password again",
      goToPasswordRecoveryScreen: "Go to password recovery screen",
      enterNewPassword: "Enter new password",
      confirmYourNewPassword: "Confirm your new password",
      submit: "Change password",
      yourPasswordSuccessfullyChanged:
        "Your password has been changed successfully",
      goToLoginScreen: "Go to login screen",
    },
    logout: {
      loggingOut: "Logging out...",
    },
    checkerboard: {
      roomCategory: "Room Category",
      dateRanges: {
        WEEK: "Week",
        MONTH: "Month",
        MONTH3: "3 Months",
      },
      createBooking: "Create Booking",
      bookingCreation: {
        title: "New Booking",
        selectPhoto: "Select Photo",
        newOrganization: "New Organization",
        selectedClient: "Selected Client:",
        fullName: {
          label: "Client's Full Name",
          placeholder: "e.g., Ivanov Ivan",
        },
        telNumber: {
          label: "Phone Number",
          placeholder: "e.g., +996 500 001 002",
        },
        citizenship: {
          label: "Citizenship",
          placeholder: "e.g., Kyrgyzstan",
        },
        email: {
          label: "Email",
          placeholder: "e.g., example@mail.com",
        },
        organization: {
          label: "Organization",
          placeholder: "e.g., AFC",
        },
        fromDate: {
          label: "Check-In Date",
          placeholder: "Select check-in date",
        },
        toDate: {
          label: "Check-Out Date",
          placeholder: "Select check-out date",
        },
        earlyCheckInTime: {
          label: "Check-In Time",
          placeholder: "Select check-in time",
        },
        lateCheckOutTime: {
          label: "Check-Out Time",
          placeholder: "Select check-out time",
        },
        earlyOrLateCheckInOutTime: {
          label: "Early Check-In or Late Check-Out",
        },
        earlyOrLateCheckInOutAdditionalFee: {
          label: "Additional Fee",
        },
        numberOfAdults: {
          label: "Adults",
        },
        numberOfChildren: {
          label: "Children",
        },
        numberOfNights: {
          label: "Number of Nights",
        },
        room: {
          label: "Room",
          placeholder: "Select room",
        },
        roomTariff: {
          label: "Tariff",
          placeholder: "Select tariff",
        },
        roomTariffDiscount: {
          label: "Discount",
          placeholder: "Select discount",
        },
        paymentTotal: {
          label: "Total Payment",
        },
        paymentSum: {
          label: "Payment",
          placeholder: "Enter amount",
        },
        paymentType: {
          label: "Payment Type",
          placeholder: "Select payment type",
        },
        notes: {
          label: "Notes",
          placeholder: "Leave booking notes",
        },
      },
      item: {
        information: "Information",
        fullName: "Full Name",
        group: "Group",
        status: "Status",
        telNumber: "Phone Number",
        organization: "Organization",
        fromDate: "Check-In Date",
        toDate: "Check-Out Date",
        earlyCheckin: "Early Check-In",
        lateCheckout: "Late Check-Out",
        numberOfGuests: "Number of Guests",
        paid: "Paid",
        debt: "Debt",
        sumToPay: "Sum to pay",
        room: "Room",
        roomCategory: "Category",
        tariff: "Tariff",
        notes: "Notes",
      },
      card: {
        title: "Booking",
        groups: {
          client: {
            groupName: "Client",
            form: {
              fullName: {
                label: "Full Name",
                placeholder: "Client's Full Name",
              },
              telNumber: {
                label: "Phone Number",
                placeholder: "+996 (500) 000-001",
              },
              citizenship: {
                label: "Citizenship",
                placeholder: "Citizenship",
              },
              hasRepresentative: {
                label: "Add client",
              },
            },
          },
          group: {
            groupName: "Group",
            form: {
              title: {
                label: "Group Title",
                placeholder: "E.g. Football Players from Uzbekistan",
              },
            },
          },
          organization: {
            groupName: "Organization",
            form: {
              hasOrganization: {
                label: "Add Organization",
              },
              title: {
                label: "Title",
                placeholder: "E.g. AFC",
              },
              telNumber: {
                label: "Phone Number",
                placeholder: "E.g. +996 (500) 000-001",
              },
            },
          },
          booking: {
            groupName: "Booking Details",
            form: {
              room: {
                label: "Room",
                placeholder: "Room Name",
              },
              tariff: {
                label: "Tariff",
                placeholder: "Tariff Name",
              },
              categories: {
                label: "Categories",
                placeholder: "Category Name",
              },
              numberOfAdults: {
                label: "Adults",
              },
              numberOfChildren: {
                label: "Children",
              },
              fromDate: {
                label: "Check-In Date",
                placeholder: "Select date",
              },
              toDate: {
                label: "Check-Out Date",
                placeholder: "Select date",
              },
              fromTime: {
                label: "Check-In Time",
                placeholder: "Select time",
              },
              toTime: {
                label: "Check-Out Time",
                placeholder: "Select time",
              },
              numberOfNights: {
                label: "Number of Nights",
              },
              numberOfHours: {
                label: "Number of Hours",
              },
              earlyOrLateCheckInOutTime: {
                label: "Early Check-In or Late Check-Out",
              },
              earlyCheckInTime: {
                label: "Early Check-In",
                placeholder: "Select",
              },
              lateCheckOutTime: {
                label: "Late Check-Out",
                placeholder: "Select",
              },
            },
            addRoom: "Add Room",
            categories: "Categories",
            tariff: "Tariff",
            fromDate: "Check-In Date",
            toDate: "Check-Out Date",
            earlyCheckin: "Early Check-In",
            lateCheckout: "Late Check-Out",
            numberOfNights: "Number of Nights",
            numberOfAdults: "Number of Adults",
            numberOfChildren: "Number of Children",
            quickFilling: "Quick Filling",
            quickFillingModal: {
              title: "Quick Filling",
              form: {
                rooms: {
                  label: "Rooms",
                  placeholder: "Room Names",
                },
                tariff: {
                  label: "Tariff",
                  placeholder: "Tariff Name",
                },
              },
            },
          },
          nutrition: {
            groupName: "Nutrition",
            kitchenMenu: {
              table: {
                title: "Title",
                date: "Serving Date",
                price: "Price",
                quantity: "Quantity",
                discount: "Discount",
                total: "Total",
              },
            },
            dish: {
              table: {
                title: "Title",
                date: "Serving Date",
                price: "Price",
                quantity: "Quantity",
                discount: "Discount",
                total: "Total",
              },
            },
          },
          additionalService: {
            groupName: "Additional Services",
            table: {
              title: "Title",
              date: "Date",
              price: "Price",
              quantity: "Quantity",
              discount: "Discount",
              total: "Total",
            },
          },
          payment: {
            groupName: "Payment",
            table: {
              title: "Title",
              date: "Date",
              price: "Price",
              quantity: "Quantity",
              discount: "Discount",
              total: "Total",
            },
          },
          cashBox: {
            groupName: "Cash Box",
            notAvailable:
              "The cash box is not available for viewing/editing <b>group</b> booking",
            notAvailableCashBoxFound: "No available cash boxes found",
            table: {
              value: "Amount",
              paymentType: "Payment Type",
              createdAt: "Date",
              createdBy: "Paid By",
              notes: "Note",
            },
            form: {
              cashBox: {
                label: "Cash Register",
                placeholder: "Select cash register",
              },
              total: {
                label: "Total",
              },
              paidSum: {
                label: "Paid",
              },
              sumToPay: {
                label: "To Pay",
              },
              totalSumToPay: {
                label: "Total to Pay",
              },
              discount: {
                label: "Discount",
                placeholder: "Select discount",
              },
              discountAmount: {
                label: "Discount Amount",
                placeholder: "Enter discount amount",
              },
              payment: {
                label: "Payment",
                placeholder: "Enter payment amount",
              },
              paymentType: {
                label: "Payment Type",
                placeholder: "Select payment type",
              },
              currency: {
                label: "Currency",
                placeholder: "Select currency",
              },
            },
          },
        },
        nutrition: {
          title: "Nutrition",
          kitchenMenu: {
            title: "Menu",
            table: {
              title: "Title",
              date: "Serving Date",
              price: "Price",
              quantity: "Quantity",
              form: {
                kitchenMenu: {
                  placeholder: "Select menu",
                },
                everyDay: {
                  label: "Every Day",
                },
                date: {
                  placeholder: "Select date",
                },
                price: {
                  label: "Price",
                },
                quantity: {
                  placeholder: "Enter quantity",
                },
              },
            },
          },
          dishes: {
            title: "Dishes",
            table: {
              title: "Title",
              date: "Serving Date",
              price: "Price",
              quantity: "Quantity",
              form: {
                dish: {
                  placeholder: "Select dish",
                },
                everyDay: {
                  label: "Every Day",
                },
                date: {
                  placeholder: "Select date",
                },
                price: {
                  label: "Price",
                },
                quantity: {
                  placeholder: "Enter quantity",
                },
              },
            },
          },
        },
        additionalServices: {
          title: "Additional Services",
          table: {
            title: "Title",
            date: "Date",
            price: "Price",
            quantity: "Quantity",
            form: {
              service: {
                placeholder: "Select service",
              },
              date: {
                placeholder: "Select date",
              },
              price: {
                label: "Price",
              },
              quantity: {
                placeholder: "Enter quantity",
              },
              everyDay: {
                label: "Every Day",
              },
            },
          },
        },
      },
      infographics: {
        load: {
          today: "Load for today",
          booked: "Total booked",
          rooms: "Total rooms",
        },
        numbers: {
          living: "Living",
          checkOuts: "Check-outs",
          bookings: "Bookings",
          inRepair: "Under repair",
          inDebt: "In debt",
          cancelled: "Cancelled bookings",
        },
        roomStates: {
          roomsState: "Room States",
          cleaned: "Cleaned",
          dirty: "Dirty",
          inRepair: "Under Repair",
        },
      },
      roomInfoCard: {
        title: "Select Booking Type",
        singleBooking: "Single Booking",
        groupBooking: "Group Booking",
        repair: "Repair",
      },
      infoCard: {
        payment: {
          title: "Payment",
          price: {
            table: {
              title: "Title",
              date: "Date",
              price: "Price",
              quantity: "Quantity",
              discount: "Discount",
              total: "Total",
            },
          },
          cashBox: {
            notAvailableCashBoxFound: "No available cash boxes found",
            form: {
              cashBox: {
                label: "Cash Box",
                placeholder: "Select cash box",
              },
              total: {
                label: "Total",
              },
              paidSum: {
                label: "Paid",
              },
              sumToPay: {
                label: "To Pay",
              },
              totalSumToPay: {
                label: "Total to Pay",
              },
              discount: {
                label: "Discount",
                placeholder: "Select discount",
              },
              discountAmount: {
                label: "Discount Amount",
                placeholder: "Enter discount amount",
              },
              payment: {
                label: "Payment",
                placeholder: "Enter payment amount",
              },
              paymentType: {
                label: "Payment Type",
                placeholder: "Select payment type",
              },
              currency: {
                label: "Currency",
                placeholder: "Select currency",
              },
            },
            table: {
              value: "Amount",
              paymentType: "Payment Type",
              createdAt: "Date",
              createdBy: "Paid By",
              notes: "Notes",
            },
          },
        },
        cancel: {
          title: "Cancel Booking",
          price: {
            table: {
              title: "Title",
              date: "Date",
              price: "Price",
              quantity: "Quantity",
              discount: "Discount",
              total: "Total",
            },
          },
          cashBox: {
            info: "Canceling the booking deducts the amount of the prepayment paid from the cash register",
            notAvailableCashBoxFound: "No available cash boxes found",
            paid: "Paid",
            form: {
              returnPayment: {
                label: "Return Payment",
              },
              payment: {
                label: "Payment",
                placeholder: "Enter payment amount",
              },
              cashBox: {
                label: "Cash Box",
                placeholder: "Select cash box",
              },
              paymentType: {
                label: "Payment Type",
                placeholder: "Select payment type",
              },
              currency: {
                label: "Currency",
                placeholder: "Select currency",
              },
            },
            table: {
              value: "Amount",
              paymentType: "Payment Type",
              createdAt: "Date",
              createdBy: "Paid By",
              notes: "Notes",
            },
          },
        },
        repair: {
          title: "Repair - {title}",
          cancelRoomRepair: "Cancel Room Repair",
        },
        changeRoom: {
          title: "Room Change",
          form: {
            room: {
              label: "Room",
              placeholder: "Select room",
            },
          },
        },
        invoice: {
          title: "Invoice",
          form: {
            language: {
              label: "Language",
              placeholder: "Select language",
            },
            individualClient: {
              label: "Individual representative",
            },
            bookingRepresentative: {
              label: "Representative",
              placeholder: "Select representative",
            },
          },
        },
      },
    },
    configuration: {
      main: {
        description: "Select the configuration you need",
      },
      systemConfigurations: {
        title: "System Configurations",
        configurationTypes: {
          time: {
            label: "Time",
            placeholder: "Select time",
          },
          percentage: {
            label: "Percentage",
            placeholder: "Enter percentage",
          },
          file: {
            label: "File",
            placeholder: "Select file",
          },
        },
        configurations: {
          booking: {
            title: "Booking",
            items: {
              additionalFeePercentageForEarlyCheckIn:
                "Additional fee for early check-in",
              additionalFeePercentageForLateCheckOut:
                "Additional fee for late check-out",
              checkInTime: "Check-in Time",
              checkOutTime: "Check-out Time",
            },
          },
          template: {
            title: "Template",
            items: {
              logo: "Logo image",
              stamp: "Stamp",
              watermark: "Watermark",
            },
          },
        },
      },
      cashBox: {
        title: "Cash box",
        box: {
          title: "Cash registers",
          addModal: {
            title: "Adding new cash box",
          },
          editModal: {
            title: "Editing cash box",
          },
        },
        sourceCategory: {
          title: `Sources' categories`,
          addModal: {
            title: "Adding new category",
          },
          editModal: {
            title: "Editing category",
          },
        },
        source: {
          title: "Sources",
          addModal: {
            title: "Adding new source",
          },
          editModal: {
            title: "Editing source",
          },
          label: {
            category: "Category",
            relatedTo: "Related to",
            prePrice: "Estimation (optional)",
            static: "Static price",
          },
          types: {
            income: "Income",
            expense: "Expense",
          },
        },
      },
      rooms: {
        title: "Rooms",
        rooms: {
          title: "Rooms",
          searchByTitle: "Search by title",
          deletionConfirmation: "Are you sure you want to delete?",
          add: "Add",
          addModal: {
            title: "Add New Room",
            form: {
              number: {
                label: "Number",
                placeholder: "Enter room number",
              },
              title: {
                label: "Title",
                placeholder: "Enter room title",
              },
              description: {
                label: "Description",
                placeholder: "Enter room description",
              },
              capacity: {
                label: "Capacity",
                placeholder: "Room capacity",
              },
              multiBooking: {
                label: "Multiple Booking",
              },
              totalCapacity: {
                label: "Total Capacity",
                placeholder: "Total room capacity",
              },
              checkInOutType: {
                label: "Booking Type",
                placeholder: "Select booking type",
              },
              floor: {
                label: "Floor",
                placeholder: "Select floor",
              },
              categories: {
                label: "Categories",
                placeholder: "Select room categories",
              },
            },
          },
          editModal: {
            title: "Edit Room",
          },
          table: {
            floor: "Floor",
            title: "Title",
            number: "Number",
            categories: "Categories",
            capacity: "Capacity",
            checkInOutType: "Booking Type",
            actions: "Actions",
          },
          tariffs: {
            discounts: {
              editModal: {
                title: "Edit Tariff Discounts",
                discounts: "Discounts",
                form: {
                  roomCategoryTitle: "Title",
                  onlyForRoom: {
                    label: "For Room Only",
                  },
                  type: {
                    label: "Discount Type",
                    placeholder: "Select discount type",
                  },
                  value: {
                    label: "Value",
                    placeholder: "Enter value",
                  },
                  maxValue: {
                    label: "Max Value",
                    placeholder: "Enter value",
                  },
                },
              },
            },
          },
        },
        floors: {
          title: "Floors",
          searchByTitle: "Search by title",
          searchByFloor: "Search by floors",
          deletionConfirmation: "Are you sure you want to delete?",
          add: "Add",
          addModal: {
            title: "Add a new floor",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter floor title",
              },
            },
          },
          editModal: {
            title: "Edit floor",
          },
          table: {
            title: "Title",
            actions: "Actions",
          },
        },
        roomCategories: {
          title: "Room Categories",
          searchByTitle: "Search by title",
          deletionConfirmation: "Are you sure you want to delete?",
          add: "Add",
          addModal: {
            title: "Add a new category",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter category title",
              },
            },
          },
          editModal: {
            title: "Edit category",
          },
          table: {
            title: "Title",
            actions: "Actions",
          },
        },
        tariffs: {
          title: "Accommodation Tariffs",
          searchByTitle: "Search by title",
          deletionConfirmation: "Are you sure you want to delete?",
          add: "Add",
          addModal: {
            title: "Add a new tariff",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter tariff title",
              },
              description: {
                label: "Description",
                placeholder: "Enter tariff description",
              },
            },
          },
          editModal: {
            title: "Edit tariff",
          },
          table: {
            title: "Title",
            description: "Description",
            actions: "Actions",
          },
        },
        services: {
          title: "Services",
          searchByTitle: "Search by title",
          deletionConfirmation: "Are you sure you want to delete?",
          add: "Add",
          addModal: {
            title: "Add a new service",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter service title",
              },
              fromDate: {
                label: "Valid From",
                placeholder: "Select date",
              },
              toDate: {
                label: "Valid Until",
                placeholder: "Select date",
              },
              fromTime: {
                label: "Time From",
                placeholder: "Select time",
              },
              toTime: {
                label: "Time Until",
                placeholder: "Select time",
              },
            },
          },
          editModal: {
            title: "Edit service",
          },
          table: {
            title: "Title",
            actions: "Actions",
            date: "Validity Period",
          },
        },
      },
      userManagement: {
        title: "User Management",
        users: {
          title: "Users",
          add: "Add",
          searchQuery: "Search by name / tel.",
          userGroup: "User Group",
          isActive: "Activity",
          deletionConfirmation: "Are you sure you want to delete?",
          addModal: {
            title: "Add New User",
            form: {
              email: {
                label: "Email",
                placeholder: "Enter email",
              },
              firstName: {
                label: "First Name",
                placeholder: "Enter first name",
              },
              lastName: {
                label: "Last Name",
                placeholder: "Enter last name",
              },
              patronymic: {
                label: "Patronymic",
                placeholder: "Enter patronymic",
              },
              telNumber: {
                label: "Tel. Number",
                placeholder: "Enter tel. number",
              },
              userGroup: {
                label: "User Group",
                placeholder: "Select user group",
              },
              address: {
                label: "Address",
                placeholder: "Enter address",
              },
              isActive: {
                label: "Activity",
              },
            },
          },
          editModal: {
            title: "Edit User",
          },
          table: {
            id: "ID",
            fullName: "Full Name",
            email: "Email",
            telNumber: "Tel. Number",
            userGroup: "User Group",
            actions: "Actions",
          },
        },
        userGroups: {
          title: "User Groups",
          add: "Add",
          userGroup: "User Group",
          deletionConfirmation: "Are you sure you want to delete?",
          addModal: {
            title: "Add New Group",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter title",
              },
              privileges: {
                label: "Privileges",
                placeholder: "Select privileges",
              },
            },
          },
          editModal: {
            title: "Edit Group",
          },
          table: {
            title: "Title",
            privileges: "Privileges",
            actions: "Actions",
          },
        },
      },
      kitchen: {
        title: "Kitchen",
        menus: {
          title: "Menus",
          searchByTitle: "Search by title",
          deletionConfirmation: "Are you sure you want to delete?",
          add: "Add",
          addModal: {
            title: "Add a new menu",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter menu title",
              },
              description: {
                label: "Description",
                placeholder: "Enter menu description",
              },
              fromDate: {
                label: "Valid From",
                placeholder: "Select date",
              },
              toDate: {
                label: "Valid Until",
                placeholder: "Select date",
              },
              fromTime: {
                label: "Serving Time From",
                placeholder: "Select time",
              },
              toTime: {
                label: "Serving Time Until",
                placeholder: "Select time",
              },
              table: {
                title: "Title",
                value: "Quantity",
                form: {
                  dish: {
                    label: "Dish",
                    placeholder: "Select dish",
                  },
                  value: {
                    label: "Quantity",
                    placeholder: "Enter quantity",
                  },
                },
              },
            },
            dishes: "Dishes",
          },
          editModal: {
            title: "Edit menu",
          },
          table: {
            title: "Title",
            description: "Description",
            dishes: "Dishes",
            date: "Validity Period",
            actions: "Actions",
          },
        },
        dishes: {
          title: "Dishes",
          searchByTitle: "Search by title",
          deletionConfirmation: "Are you sure you want to delete?",
          add: "Add",
          addModal: {
            title: "Add a new dish",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter dish title",
              },
              unit: {
                label: "Unit of Measurement",
                placeholder: "Select unit of measurement",
              },
              description: {
                label: "Description",
                placeholder: "Enter dish description",
              },
              fromDate: {
                label: "Valid From",
                placeholder: "Select date",
              },
              toDate: {
                label: "Valid Until",
                placeholder: "Select date",
              },
              fromTime: {
                label: "Serving Time From",
                placeholder: "Select time",
              },
              toTime: {
                label: "Serving Time Until",
                placeholder: "Select time",
              },
              table: {
                title: "Title",
                value: "Quantity",
                servingDate: "Quantity",
                form: {
                  ingredient: {
                    label: "Ingredient",
                    placeholder: "Select ingredient",
                  },
                  value: {
                    label: "Quantity",
                    placeholder: "Enter quantity",
                  },
                },
              },
            },
            ingredients: "Ingredients",
          },
          editModal: {
            title: "Edit dish",
          },
          table: {
            title: "Title",
            unit: "Unit of Measurement",
            description: "Description",
            ingredients: "Ingredients",
            date: "Validity Period",
            actions: "Actions",
          },
        },
        ingredients: {
          title: "Ingredients",
          searchByTitle: "Search by title",
          deletionConfirmation: "Are you sure you want to delete?",
          add: "Add",
          addModal: {
            title: "Add a new ingredient",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter ingredient title",
              },
              unit: {
                label: "Unit of Measurement",
                placeholder: "Select unit of measurement",
              },
            },
          },
          editModal: {
            title: "Edit ingredient",
          },
          table: {
            title: "Title",
            unit: "Unit of Measurement",
            actions: "Actions",
          },
        },
      },
      finance: {
        title: "Finance",
        roomCategories: {
          title: "Room Categories",
          searchByTitle: "Search by title",
          editModal: {
            title: "Edit Prices",
            form: {
              title: "Title",
              fromDate: {
                label: "From Date",
                placeholder: "Select date",
              },
              toDate: {
                label: "To Date",
                placeholder: "Select date",
              },
              price: {
                label: "Price",
                placeholder: "Enter price",
              },
              service: {
                label: "Service",
                placeholder: "Select service",
              },
              serviceType: {
                label: "Type",
                placeholder: "Select service type",
              },
              count: {
                label: "Count",
                placeholder: "Enter count",
              },
              kitchenMenu: {
                label: "Menu",
                placeholder: "Select menu",
              },
              fixed: {
                label: "Fixed",
              },
            },
            prices: "Prices",
            services: "Services",
            kitchenMenus: "Menus",
          },
          table: {
            title: "Title",
            price: "Price",
            actions: "Actions",
          },
        },
        dishes: {
          title: "Dishes",
          searchByTitle: "Search by title",
          editModal: {
            title: "Edit Prices",
            form: {
              title: "Title",
              fromDate: {
                label: "From Date",
                placeholder: "Select date",
              },
              toDate: {
                label: "To Date",
                placeholder: "Select date",
              },
              price: {
                label: "Price",
                placeholder: "Enter price",
              },
            },
            prices: "Prices",
          },
          table: {
            title: "Title",
            price: "Price",
            actions: "Actions",
          },
        },
        services: {
          title: "Services",
          searchByTitle: "Search by title",
          editModal: {
            title: "Edit Prices",
            form: {
              title: "Title",
              fromDate: {
                label: "From Date",
                placeholder: "Select date",
              },
              toDate: {
                label: "To Date",
                placeholder: "Select date",
              },
              price: {
                label: "Price",
                placeholder: "Enter price",
              },
            },
            prices: "Prices",
          },
          table: {
            title: "Title",
            price: "Price",
            actions: "Actions",
          },
        },
        paymentTypeCategories: {
          title: "Payment Type Categories",
          addModal: {
            title: "Add New Payment Type Category",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter the title for the payment type category",
              },
            },
          },
          editModal: {
            title: "Edit Payment Type Category",
          },
          table: {
            title: "Title",
            actions: "Actions",
          },
        },
        paymentTypes: {
          title: "Payment Types",
          addModal: {
            title: "Add New Payment Type",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter the title for the payment type",
              },
              type: {
                label: "Payment Type Category",
              },
            },
          },
          editModal: {
            title: "Edit Payment Type",
          },
          table: {
            title: "Title",
            type: "Payment Type",
            actions: "Actions",
          },
        },
        exchangeRateGroups: {
          title: "Currency Exchange",
          addModal: {
            title: "Add New Currency Exchange",
            form: {
              fromDate: {
                label: "Activation Date",
                placeholder: "Select date",
              },
              defaultCurrency: {
                label: "Base Currency",
              },
              currency: {
                label: "Currency",
              },
              currencyValue: {
                label: "Exchange Rate",
                placeholder: "Enter rate",
              },
            },
          },
          table: {
            createdAt: "Creation Date",
            fromDate: "Activation Date",
            defaultCurrency: "Base Currency",
          },
        },
      },
      userSheet: {
        title: "Timesheet",
        rates: {
          title: "Rates",
          searchByPosition: "Search by position",
          position: "Position",
          shiftName: "Shift",
          coefficient: "Coefficient",
          workHours: "Work Hours",
          actualRate: "Actual Rate",
          rate: "Rate",
          paymentFrom: "Payment From",
          paymentTo: "Payment To",
          actions: "Actions",
          modal: {
            addTitle: "Add Rate",
            editTitle: "Edit Rate",
            showHistoryTitle: "Payment History for Shift",
            doNotFillFields: 'Leave the "Working Hours" and "Rate" fields blank for those shifts that will not appear on the Timesheet for this position'
          },
          labels: {
            userGroup: "Position",
            shift: "Shift",
            coefficient: "Coefficient",
            workHours: "Work Hours",
            actualRate: "Actual Rate",
            from: "from",
            rate: "Rate",
            paymentFrom: "Payment From",
          },
          deletionConfirmation: "Are you sure you want to delete?",
          deletionConfirmationText:
            "All shift payment information for this position will be deleted",
        },
        shifts: {
          title: "Shifts",
          modal: {
            addTitle: "Add Shift",
            editTitle: "Edit Shift",
          },
          shiftName: "Shift",
          coefficient: "Coefficient",
          actions: "Actions",
          deletionConfirmation: "Are you sure you want to delete?",
        },
        daysConfig: {
          title: "Month Summary Days Configuration",
          titleShort: "Summary Days",
          modal: {
            addTitle: "Add",
            editTitle: "Edit",
          },
          daysCount: "Number of Days",
          from: "From",
          to: "To",
          actions: "Actions",
          deletionConfirmation: "Are you sure you want to delete?",
          fullMonth: "Full Month",
        },
      },
      templateTypes: {
        title: "Template Types",
        table: {
          type: "Type",
          actions: "Actions",
        },
        templates: {
          title: "Template",
          add: "Add",
          deletionConfirmation: "Are you sure you want to delete?",
          table: {
            language: "Language",
            actions: "Actions",
          },
          editModal: {
            title: "Edit Template",
            form: {
              language: {
                label: "Language",
                placeholder: "Select language",
              },
              content: {
                label: "Content",
              },
            },
            restoreModal: {
              title: "Template Restoration",
              form: {
                file: {
                  label: "File",
                  placeholder: "Select file",
                },
              },
            },
          },
        },
      },
    },
    clients: {
      tabs: {
        clients: {
          title: "Clients",
          add: "Add",
          searchQuery: "Search by name / phone",
          deletionConfirmation: "Are you sure you want to delete?",
          addModal: {
            title: "Add a new client",
            isAllowed: {
              title: "Do you allow to upload photo?",
              yes: "yes",
              no: "no",
            },
            form: {
              fullName: {
                label: "Full Name",
                placeholder: "Enter full name",
              },
              telNumber: {
                label: "Phone Number",
                placeholder: "Enter phone number",
              },
              citizenship: {
                label: "Citizenship",
                placeholder: "Select citizenship",
              },
            },
          },
          editModal: {
            title: "Edit client",
          },
          table: {
            id: "ID",
            fullName: "Full Name",
            telNumber: "Phone Number",
            citizenship: "Citizenship",
            actions: "Actions",
          },
        },
        organizations: {
          title: "Organizations",
          add: "Add",
          searchQuery: "Search by name",
          deletionConfirmation: "Are you sure you want to delete?",
          addModal: {
            title: "Add a new organization",
            form: {
              title: {
                label: "Title",
                placeholder: "Enter title",
              },
              telNumber: {
                label: "Phone Number",
                placeholder: "Enter phone number",
              },
            },
          },
          editModal: {
            title: "Edit organization",
          },
          table: {
            id: "ID",
            title: "Title",
            telNumber: "Phone Number",
            actions: "Actions",
          },
        },
      },
    },
    userSheet: {
      oneWeek: "Week",
      oneMonth: "Month",
      threeMonths: "3 months",
      rate: "Rate",
      shiftCount: "Shift Count",
      prepayment: "Prepayment",
      bonus: "Bonus",
      penalty: "Penalty",
      debt: "Debt",
      salary: "Salary",
      totalPeriod: "Total",
      totalMonth: "Total for the month",
      totalPaid: "Paid",
      living: "Residents",
      clientBreakfast: "Breakfast",
      conference: "Conference",
      morningCoffeeBreak: "Coffee Break #1",
      lunchCoffeeBreak: "Coffee Break #2",
      lunchClient: "Lunch",
      dinnerClient: "Dinner",
      breakfastUser: "Staff Breakfast",
      lunchUser: "Staff Lunch",
      dinnerUser: "Staff Dinner",
      number: "Number",
      fullName: "Full Name",
      position: "Position",
      actions: {
        addingAdditionalCharge: "Add Advance/Penalty/Bonus/Debt",
        generateFromPreviousMonth: "Generate from previous month",
      },
      debtAlertText: `Only for debts in the form of money!\nIf it's a service or product, enter through the cash-box.`,
      overnight: "Overnight",
      breakfast: "Breakfast",
      lunch: "Lunch",
      dinner: "Dinner",
      paySalary: {
        payingSalary: "Paying salary",
        availableToPay: "Available to pay",
      },
    },
    cashBox: {
      mainCashBox: 'Main cash-box',
      shift: "Shift",
      cashBox: "Cash Box",
      time: "Time",
      startedAt: "Started at",
      closedAt: "Closed at",
      source: "Source",
      dateTime: 'Datetime',
      num: "№",
      contractor: "Contractor",
      searchContractor: "Search Contractor",
      notes: "Notes",
      sum: "Sum",
      currency: "Currency",
      paymentType: "Payment type",
      beginningShiftBalance: "Beginning shift balance",
      paid: "Paid",
      balance: "Balance",
      inCurrency: "in currency",
      total: "Total",
      totalWithDebt: "Total in system",
      allTotal: "Total",
      closedEmpty: "Cash-box closed empty",
      shortage: "Shortage",
      surplus: "Surplus",
      fullName: "Full name",
      clients: "Clients",
      staffs: "Staff",
      organizations: "Organizations",
      startShift: "Open shift",
      closedShift: "Closed shift",
      openedShift: "Opened shift",
      income: {
        income: "Income",
        debtShort: "Debt",
        debtLong: "Debt",
        repayDebt: "Repay debt",
      },
      expense: {
        expense: "Expense",
        debtShort: "Debt",
        debtLong: "Debt",
        repayDebt: "Repay debt",
      },
      noOpenShift: "No open shift",
      startCashBoxModal: {
        title: "Start a new shift",
        cashBox: "Cash Register",
        startPaymentTitle: "Money at the start of the shift",
        leftPaymentTitle: "Remaining from the previous shift",
        payment: "Payment",
        total: "Total",
        alertDesc:
          "The added amount and the balance from the previous shift of the same currencies and payment types will be summed up",
      },
      sendToCashBoxModal: {
        title: "Sending money to the cash-box",
        send: "Send money to the cash-box",
        cashBox: "Opened cash-box",
        payment: "Payment",
      },
      closeTransferCashBoxModal: {
        closeTitle: "Closing Shift",
        transferTitle: "Shift Handover",
        payment: "Payment",
        transferAmount: "Transfer Amount",
        factAmount: "Actual Amount",
        inSystem: "In system",
        inFact: "In fact",
        difference: "Difference",
        noShortagesAndSurpluses: "No shortages and surpluses",
        shortageReason: "Shortage reason",
        surplusReason: "Surplus reason",
        summarizeAmounts: "Summarize amounts",
        shortagesAndSurpluses: "Shortages and surpluses",
        transferShift: "Transfer shift",
        takingSideData: "Taking side data",
      },
      collectionShiftModal: {
        title: "Collection of cash-box",
        payment: "Payment",
        availableSum: "Available amount",
        collectionSum: "Collection sum",
        collectorData: "Collector's data"
      },
    },
    profile: {
      title: "Profile",
      form: {
        fullName: {
          label: "Full Name",
        },
        email: {
          label: "Email",
        },
        telNumber: {
          label: "Tel. Number",
        },
        userGroup: {
          label: "Position",
        },
        createdAt: {
          label: "Registration Date",
        },
      },
    },
    todo: {
      tasks: 'Tasks',
      cleaning: 'Cleaning',
      actual: 'Actual',
      done: 'Done',
      checkList: 'Check list',
      roomCleaningList: `Rooms' list for cleaning`,
      modal: {
        addTitle: 'Adding task',
        editTitle: 'Editing task',
        executor: 'Executor',
        deadline: 'Deadline',
        priority: 'Priority',
        description: 'Description',
        cleaner: 'Cleaner',
        number: 'Number',
        cleaningType: 'Cleaning',
        notes: 'Notes',
        cleaningDate: 'Cleaning date'
      },
      priorities: {
        LOW: 'Low',
        MEDIUM: 'Medium',
        HIGH: 'High',
        ASAP: 'Urgent'
      },
      cleaningStatuses: {
        CLEAN: 'Clean',
        DIRTY: 'Dirty'
      },
      cleaningTypes: {
        CHECK: 'Check',
        WET: 'Wet',
        DAILY: 'Current',
        EXPRESS: 'Express',
        FULL: 'Full'
      },
      statuses: {
        CREATED: 'Not started',
        IN_PROCESS: 'In process',
        DONE: 'Done'
      },
      cleanerFullName: `Cleaners' full name`
    },
    accessDenied: {
      title: "Access Denied",
    },
    pageNotFound: {
      title: "Page Not Found",
    },
  },
  exchange: {
    exchangeTo: "Exchange to",
    rate: "Rate",
    impossibleToExchange: "Exchanging to current currency is impossible.",
    toCashBox: "to cash box",
    toUserSheet: "to user sheet",
  },
  infographics: {
    hotelLoad: "Hotel occupancy",
    choosePeriod: "Choose period",
    today: "Today",
    last7Days: "Last 7 days",
    lastMonth: "Last month",
    load: "Occupancy",
    loadShort: "Occ.",
    totalSum: "Total amount",
    allRooms: "Total rooms",
    busyRooms: "Total occupied",
    salesFor: "Sales for",
    source: "Source",
    debt: "Debt",
    debtShort: "Debt",
    quantity: "Quantity",
  },
  payment: {
    type: {
      CASH: {
        long: "Cash",
        short: "Cash",
      },
      E_WALLET: {
        long: "E-wallets",
        short: "E-wallets",
      },
      BANK_CARD: {
        long: "Bank card",
        short: "Bank card",
      },
      BANK_TRANSFER: {
        long: "Bank transfer",
        short: "Bank transfer",
      },
      OTHER: {
        long: "Other",
        short: "Other",
      },
    },
    currency: {
      ALL: "Albanian lek",
      AFN: "Afghan afghani",
      ARS: "Argentinian peso",
      AWG: "Aruban guilder",
      AUD: "Australian dollar",
      AZN: "Azerbaijani manat",
      BSD: "Bahamian dollar",
      BBD: "Barbadian dollar",
      BYN: "Belarusian ruble",
      BZD: "Belize dollar",
      BMD: "Bermudian dollar",
      BOB: "Bolivian bolíviano",
      BAM: "Bosnia and Herzegovina convertible mark",
      BWP: "Botswana pula",
      BGN: "Bulgarian lev",
      BRL: "Brazilian real",
      BND: "Brunei Darussalam dollar",
      KHR: "Cambodian riel",
      CAD: "Canadian dollar",
      KYD: "Cayman Islands dollar",
      CLP: "Chilean peso",
      CNY: "Chinese yuan renminbi",
      COP: "Colombian peso",
      CRC: "Costa Rican colón",
      HRK: "Croatian kuna",
      CUP: "Cuban peso",
      CZK: "Czech Republic koruna",
      DKK: "Danish krone",
      DOP: "Dominican Republic peso",
      XCD: "East Caribbean dollar",
      EGP: "Egyptian pound",
      SVC: "Salvadoran colón",
      EUR: "Euro member countries",
      FKP: "Falkland Islands (Malvinas) pound",
      FJD: "Fijian dollar",
      GHS: "Ghanaian cedi",
      GIP: "Gibraltar pound",
      GTQ: "Guatemalan quetzal",
      GGP: "Guernsey pound",
      GYD: "Guyanese dollar",
      HNL: "Honduran lempira",
      HKD: "Hong Kong dollar",
      HUF: "Hungarian forint",
      ISK: "Icelandic króna",
      INR: "Indian rupee",
      IDR: "Indonesian rupiah",
      IRR: "Iranian rial",
      IMP: "Isle of Man pound",
      ILS: "Israeli shekel",
      JMD: "Jamaican dollar",
      JPY: "Japanese yen",
      JEP: "Jersey pound",
      KZT: "Kazakhstani tenge",
      KPW: "North Korean won",
      KRW: "South Korean won",
      KGS: "Kyrgyzstani som",
      LAK: "Laotian kip",
      LBP: "Lebanese pound",
      LRD: "Liberian dollar",
      MKD: "Macedonian denar",
      MYR: "Malaysian ringgit",
      MUR: "Mauritian rupee",
      MXN: "Mexican peso",
      MNT: "Mongolian tugrik",
      MAD: "Moroccan dirham",
      MZN: "Mozambican metical",
      NAD: "Namibian dollar",
      NPR: "Nepalese rupee",
      ANG: "Netherlands Antilles guilder",
      NZD: "New Zealand dollar",
      NIO: "Nicaraguan córdoba",
      NGN: "Nigerian naira",
      NOK: "Norwegian krone",
      OMR: "Omani rial",
      PKR: "Pakistani rupee",
      PAB: "Panamanian balboa",
      PYG: "Paraguayan guaraní",
      PEN: "Peruvian sol",
      PHP: "Philippine peso",
      PLN: "Polish zloty",
      QAR: "Qatari riyal",
      RON: "Romanian leu",
      RUB: "Russian ruble",
      SHP: "Saint Helena pound",
      SAR: "Saudi Arabian riyal",
      RSD: "Serbian dinar",
      SCR: "Seychellois rupee",
      SGD: "Singapore dollar",
      SBD: "Solomon Islands dollar",
      SOS: "Somali shilling",
      ZAR: "South African rand",
      LKR: "Sri Lankan rupee",
      SEK: "Swedish krona",
      CHF: "Swiss franc",
      SRD: "Surinamese dollar",
      SYP: "Syrian pound",
      TWD: "Taiwanese new dollar",
      THB: "Thai baht",
      TTD: "Trinidad and Tobago dollar",
      TRY: "Turkish lira",
      TVD: "Tuvaluan dollar",
      UAH: "Ukrainian hryvnia",
      AED: "UAE dirham",
      GBP: "British pound",
      USD: "United States dollar",
      UYU: "Uruguayan peso",
      UZS: "Uzbekistani som",
      VEF: "Venezuelan bolívar",
      VND: "Vietnamese dong",
      YER: "Yemeni rial",
      ZWD: "Zimbabwean dollar",
    },
  },
  sources: {
    categories: {
      USER_SHEET: "Employee Timesheet",
      CASH_BOX_DEBT: "Debt",
      BOOKING: "Booking",
      CASH_BOX_REFILL: "Refilling from cash-box",
      CASH_BOX_FILL: "Filling cash-box",
      CASH_BOX_COLLECTION: "Cash collection",
      CASH_BOX_TRANSFER: "Transfer to the cash-box",
      OTHER: "Other",
    },
    DEBT_REFUND: "Debt Refund",
    PREPAYMENT_REFUND: "Prepayment Refund",
    SALARY: "Salary",
    DEBT: "Debt",
    PREPAYMENT: "Prepayment",
    REFUND: "Refund",
    BOOKING_PAYMENT: "Booking Payment",
    BOOKING_PAYMENT_REFUND: "Booking Payment Refund",
    FROM_MAIN_CASH_BOX: "From main cash-box",
    FROM_OUTSOURCE: "From external sources",
    TO_MAIN_CASH_BOX: "To main cash-box",
  },
  reports: {
    reports: "Reports",
    periodSorting: "Sorting for Period",
    last7Days: "last 7 days",
    lastMonth: "last month",
    last3Months: "last 3 months",
    reportFilter: "Include in report:",
    userFilter: "Personnel filter:",
    all: "All",
    allUsers: "All personnel",
    averageRate: "Avg. rate per shift",
    shiftCount: "Shift count",
    salary: "Salary",
    prepayment: "Prepayments",
    bonus: "Bonuses",
    penalty: "Penalties",
    debt: "Debts",
    food: "Food",
    totalSalary: "Total Salary",
    paidSalary: "Paid Salary",
    table: {
      number: "№",
      date: "Date",
      fullName: "Full Name",
      userGroupAlt: "Position",
      averageRate: "Avg. rate",
      shiftCount: "Shift count",
      salary: "Salary",
      prepayment: "Prepayment",
      bonus: "Bonus",
      penalty: "Penalty",
      debt: "Debt",
      breakfast: "Breakfast",
      lunch: "Lunch",
      dinner: "Dinner",
      totalSalary: "Total salary",
      paidSalary: "Already paid",
      source: "Source",
      contractor: "Contractor",
      amount: "Sum",
      paymentType: "Payment type",
      notes: "Notes",
      serviceGroup: "Service group",
      title: "Title",
      count: "Quantity",
      payments: "Payments",
      services: "Services",
      incomeInCurrency: "Income in ({currency})",
      expenseInCurrency: "Expense in ({currency})",
      incomeTotal: "Income total",
      expenseTotal: "Expense total",
      profit: "Profit",
      cashBoxDebt: "Debt",
      total: "Total",
      byPaymentType: "By payment method",
      byCurrency: "By other currencies",
      incomeBySource: "Income by sources",
    },
    reportType: "Report Type",
    period: "Period",
    filter: "Filter",
    total: "Total",
    incomeFilter: "Filter by income",
    expenseFilter: "Filter by expense",
    income: "Income",
    expense: "Expense",
    pnl: "PnL",
    finReport: "Finance Report",
    totalByPaymentGroup: 'Total by "Payments" group',
    totalByServiceGroup: 'Total by "Services" group',
    pnlTitle: "Profit and Loss (P&L)",
    hotelLoad: "Hotel load",
  },
  userGroups: {
    manager: "Manager",
    waiter: "Waiter",
    worker: "Worker",
    admin: "Administrator",
    maid: "Maid",
    pastryChef: "Pastry Chef",
    technician: "Technician",
    dishwasher: "Dishwasher",
    cashier: "Cashier",
    cook: "Cook",
    cleaner: 'Cleaner',
    laundress: "Laundress",
    reception: "Reception",
    security: "Security",
    electrician: "Electrician",
    other: "Other",
  },
  templatesTypes: {
    BOOKING_CONFIRMATION: "Booking confirmation",
    BOOKING_INVOICE: "Booking invoice",
    BOOKING_INVOICE_FOR_PAYMENT: "Booking invoice for payment",
    TODO_CLEANING_CHECK_LIST: "Cleaning check-list"
  },
  bookingStatuses: {
    CANCEL: "Cancelled",
    CREATE: "Created",
    CHECK_IN: "Checked In",
    CHECK_OUT: "Checked Out",
  },
  $vuetify: {
    badge: "Badge",
    close: "Close",
    dataIterator: {
      noResultsText: "No matching records found",
      loadingText: "Loading items...",
    },
    dataTable: {
      itemsPerPageText: "Rows per page:",
      ariaLabel: {
        sortDescending: "Sorted descending.",
        sortAscending: "Sorted ascending.",
        sortNone: "Not sorted.",
        activateNone: "Activate to remove sorting.",
        activateDescending: "Activate to sort descending.",
        activateAscending: "Activate to sort ascending.",
      },
      sortBy: "Sort by",
    },
    dataFooter: {
      itemsPerPageText: "Items per page:",
      itemsPerPageAll: "All",
      nextPage: "Next page",
      prevPage: "Previous page",
      firstPage: "First page",
      lastPage: "Last page",
      pageText: "{0}-{1} of {2}",
    },
    datePicker: {
      itemsSelected: "{0} selected",
      nextMonthAriaLabel: "Next month",
      nextYearAriaLabel: "Next year",
      prevMonthAriaLabel: "Previous month",
      prevYearAriaLabel: "Previous year",
    },
    noDataText: "No data available",
    carousel: {
      prev: "Previous visual",
      next: "Next visual",
      ariaLabel: {
        delimiter: "Carousel slide {0} of {1}",
      },
    },
    calendar: {
      moreEvents: "{0} more",
    },
    input: {
      clear: "Clear {0}",
      prependAction: "{0} prepended action",
      appendAction: "{0} appended action",
    },
    fileInput: {
      counter: "{0} files",
      counterSize: "{0} files ({1} in total)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Pagination Navigation",
        next: "Next page",
        previous: "Previous page",
        page: "Goto Page {0}",
        currentPage: "Current Page, Page {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Rating {0} of {1}",
      },
    },
    loading: "Loading...",
  },
  formLabels: {
    from: "From",
    to: "To",
    sum: "Amount",
    currency: "Currency",
    paymentType: "Payment Type",
    notes: "Notes",
    enterComment: "Enter comment",
    date: "Date",
    searchByTitle: "Search by title",
    refund: "Refund",
    cashBox: "Cash box",
    title: "Title",
  },
  table: {
    title: "Title",
    actions: "Actions",
    type: "Type",
    price: "Price",
    category: "Category",
    amount: 'Sum',
    paymentType: 'Payment type',
    currency: 'Currency',
    num: '№',
    supervisor: 'Supervisor',
    executor: 'Executor',
    where: 'Where',
    description: 'Description',
    createdAt: 'Created at',
    deadline: 'Deadline',
    status: 'Status',
    priority: 'Priority',
    number: 'Room',
    numberType: 'Room type',
    cleaning: 'Cleaning',
    checkIn: 'Check in',
    checkOut: 'Check out',
    guestFullName: 'Guest name',
    cleanerFullName: 'Cleaner name',
    adults: 'Adults',
    children: 'Children',
    bookingStatus: 'Booking status',
    numberStatus: 'Room status',
    notes: 'Notes'
  },
  form: {
    fromDate: {
      label: "Date from",
      placeholder: "Enter a date",
    },
    toDate: {
      label: "Date to",
      placeholder: "Enter a date",
    },
  },
  buttons: {
    disclose: "Disclose",
    close: "Close",
    reset: "Reset",
    form: "Generate",
    remove: "Remove",
    save: "Save",
    cancel: "Cancel",
    add: "Add",
    back: "Back",
    edit: "Edit",
    view: "View",
    movement: "Move",
    checkIn: "Check-in",
    checkOut: "Check-out",
    payment: "Payment",
    invoiceForPayment: "Invoice for Payment",
    invoice: "Invoice",
    confirmationInvoice: "Booking Confirmation",
    addPayment: "Add Payment",
    closeShift: "Close Shift",
    sendToCashBox: "Send to cash-box",
    transferShift: "Shift-Handover",
    collectionShift: "Collect cash",
    submit: "Submit",
    getShift: "Take shift",
    send: 'Send',
    openShift: 'Open shift',
    collect: 'Collect'
  },
};
