import gql from 'graphql-tag'

export const GET_ROOMS_PAGINATION_QUERY = gql`
  query GetRoomsPagination (
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
    $query: String
    $floorId: Int
  ) {
    getRooms(
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
        query: $query
        floorId: $floorId
      }
    ) {
      hasMore
      total
      items {
        id
        number
        title
        capacity
        checkInOutType
        multiBooking
        totalCapacity
        floor {
          id
          title
        }
        categories {
          id
          title
        }

      }
    }
  }
`
