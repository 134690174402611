import gql from 'graphql-tag'

export const GET_MAIN_CASH_BOX_SHIFT_DATA_PAGINATION_QUERY = gql`
  query GetMainCashBoxShiftDataPagination (
		$skip: Int!,
		$take: Int!,
		$orderBy: [OrderByInput!]
	) {
		getMainCashBoxShiftData (
			data: {
				orderBy: $orderBy
				pagination: {
					skip: $skip
					take: $take
				}
			}
		) {
			hasMore
			total
			items {
				id
				type
				user {
					id
					fullName
				}
				client {
					id
					fullName
				}
				organization {
					id
					title
				}
				payment {
					id
					currencyTitle
					paymentType {
						id
						category {
							title
						}
						title
					}
					amount
				}
				notes
				source {
					id
					title
					category {
						id
						title
					}
					type
					notShow
					static
				}
				withdrawn
				withdrawReason
				createdAt
			}
		}
	}
`
