import { setContext } from "apollo-link-context";
import {
  getFromLocalStorage, getObjectFromLocalStorage,
  setToLocalStorage
} from '@/utils/local-storage.util';
import { onError } from "apollo-link-error";
import { ApolloClient } from "apollo-client";
import { ApolloLink, Observable } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import { handleGqlResponseBody } from "@/utils/apollo.util";
import { REFRESH_TOKEN_MUTATION } from "@/graphql/mutations/refresh-token.mutation";
import { clearLoginData } from "@/store/modules/authentication";
import { createUploadLink } from "apollo-upload-client";

const parseHeaders = (rawHeaders) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(":");
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(":").trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url, options) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ""),
      };
      opts.url =
        "responseURL" in xhr
          ? xhr.responseURL
          : opts.headers.get("X-Request-URL");
      const body = "response" in xhr ? xhr.response : xhr.responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.ontimeout = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

const customFetch = (uri, options) => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const httpLink = createUploadLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
  fetch: customFetch,
});

const authLink = setContext(async (ctx, { headers, ...rest }) => {
  const language = getObjectFromLocalStorage('lang')
  const token = getFromLocalStorage("accessToken");
  if (!token) {
    return { ...headers, 'Accept-Language': language };
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
      'Accept-Language': language
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        // eslint-disable-next-line
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    // eslint-disable-next-line
    if (networkError) console.error(`[Network error]: ${networkError}`);

    // User access token has expired
    if (graphQLErrors && graphQLErrors[0].message === "Unauthorized") {
      // We assume we have both tokens needed to run the async request
      const accessToken = getFromLocalStorage("accessToken");
      const refreshToken = getFromLocalStorage("refreshToken");
      if (refreshToken && accessToken) {
        // Let's refresh token through async request
        return new Observable((observer) => {
          handleGqlResponseBody(({ mutation }) => {
            return mutation({
              mutation: REFRESH_TOKEN_MUTATION,
              variables: { refreshToken },
            });
          })
            .then(({ accessToken }) => {
              setToLocalStorage("accessToken", accessToken);
              operation.setContext(({ headers = {} }) => ({
                headers,
              }));
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              // Retry last failed request
              forward(operation).subscribe(subscriber);
            })
            .catch((error) => {
              clearLoginData();
              // No refresh or client token available, we force user to login
              observer.error(error);
            });
        });
      }
    }
  }
);

// create the apollo client
export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
