import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_PAYMENT_TYPES_PAGINATION_QUERY } from "@/graphql/queries/configuration/payment-type/get-payment-types-pagination.query";
import {
  GET_PAYMENT_TYPE_CATEGORIES_PAGINATION_QUERY
} from '@/graphql/queries/configuration/payment-type-category/get-payment-type-categories-pagination.query';

const paymentType = {
  actions: {
    async getPaymentTypesQuery({ commit }, { skip, take, orderBy, search }) {
      return await handleGqlResponseBody(({ query }) => {
        return query({
          query: GET_PAYMENT_TYPES_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query: search,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getPaymentTypeCategoriesQuery({ commit }, { skip, take, orderBy, search }) {
      return await handleGqlResponseBody(({ query }) => {
        return query({
          query: GET_PAYMENT_TYPE_CATEGORIES_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query: search,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default paymentType;
