import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "@/views/PageNotFound";
import MainComponent from "@/components/MainComponent";
import AuthComponent from "@/components/AuthComponent";
import { getFromLocalStorage } from "@/utils/local-storage.util";
import AccessDenied from "@/views/AccessDenied";
import LogoutView from "@/views/LogoutView";
import {
  changeUserPrivileges,
  USER_PRIVILEGES,
} from "@/store/modules/authentication";
import { TOP_BAR_TAB_ITEMS } from "@/utils/top-bar.util";
import { privilegeMixin } from "@/mixins/privilege.mixin";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: MainComponent,
    children: [
      {
        path: "",
        component: () => import("../views/main/HomeView.vue"),
        meta: {
          auth: true,
        },
        children: [
          // {
          //   path: "",
          //   redirect: to => {
          //     // the function receives the target route as the argument
          //     // we return a redirect path/location here.
          //     console.log('HOMEEEEEE-2--2--2--2-2-2-', USER_PRIVILEGES, to)
          //     // if (!USER_PRIVILEGES) {
          //     //   return { path: '/' };
          //     // }
          //     if (USER_PRIVILEGES){
          //       for (const item of TOP_BAR_TAB_ITEMS) {
          //         // if (item.privileges.some(privilege => USER_PRIVILEGES[privilege])) {
          //         //   return { path: item.to };
          //         // }
          //         if (privilegeMixin.methods.haveOneOfPrivileges(item.privileges)) {
          //           return { path: item.to };
          //         }
          //       }
          //       return { path: '/access-denied' }
          //     }
          //   },
          // },
          {
            path: "profile",
            name: "profile",
            component: () => import("../views/main/ProfileView.vue"),
          },
          {
            path: "checkerboard",
            name: "checkerboard",
            component: () => import("../views/main/CheckerboardView.vue"),
            meta: {
              permissions: ["checkerboard-get"],
            },
          },
          {
            path: "cashbox",
            name: "cashbox",
            component: () => import("../views/main/CashBoxView.vue"),
            meta: {
              permissions: ["cashbox-get"],
            },
          },
          {
            path: "user-sheet",
            name: "user-sheet",
            component: () => import("../views/main/UserSheetView.vue"),
            meta: {
              permissions: ["usersheet-get-table_data"],
            },
          },
          {
            path: "todo",
            name: "todo",
            component: () => import("../views/main/TodoView.vue"),
            meta: {
              permissions: ["todo-get"],
            },
          },
          {
            path: "clients",
            name: "clients",
            component: () => import("../views/main/ClientsView.vue"),
            meta: {
              permissions: ["client-get"],
            },
          },
          {
            path: "configuration",
            component: () => import("../layouts/ConfigurationLayout.vue"),
            children: [
              {
                path: "",
                name: "main-configurations",
                component: () => import("../views/configurations/MainView.vue"),
                meta: {
                  permissions: ["admin-get"],
                },
              },
              {
                path: "system",
                name: "system-configurations",
                component: () =>
                  import("../views/configurations/SystemView.vue"),
                meta: {
                  permissions: ["admin-get-system_configs"],
                },
              },
              {
                path: "rooms",
                component: () => import("../layouts/EmptyLayout.vue"),
                children: [
                  {
                    path: "rooms",
                    name: "rooms-configurations",
                    component: () =>
                      import("../views/configurations/Rooms/RoomsView.vue"),
                    meta: {
                      permissions: ["admin-get-rooms"],
                    },
                  },
                  {
                    path: "floors",
                    name: "floors-configurations",
                    component: () =>
                      import("../views/configurations/Rooms/FloorsView.vue"),
                    meta: {
                      permissions: ["admin-get-floors"],
                    },
                  },
                  {
                    path: "categories",
                    name: "categories-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Rooms/RoomCategoriesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-room_categories"],
                    },
                  },
                  {
                    path: "services",
                    name: "services-configurations",
                    component: () =>
                      import("../views/configurations/Rooms/ServicesView.vue"),
                    meta: {
                      permissions: ["admin-get-services"],
                    },
                  },
                  {
                    path: "tariffs",
                    name: "tariffs-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Rooms/RoomTariffsView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-accommodation_tariffs"],
                    },
                  },
                ],
              },
              {
                path: "user-management",
                component: () => import("../layouts/EmptyLayout.vue"),
                children: [
                  {
                    path: "user-groups",
                    name: "user-groups-configurations",
                    component: () =>
                      import(
                        "../views/configurations/UserManagement/UserGroupsView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-user_groups"],
                    },
                  },
                  {
                    path: "users",
                    name: "users-configurations",
                    component: () =>
                      import(
                        "../views/configurations/UserManagement/UsersView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-users"],
                    },
                  },
                ],
              },
              {
                path: "kitchen",
                component: () => import("../layouts/EmptyLayout.vue"),
                children: [
                  {
                    path: "ingredients",
                    name: "ingredients-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Kitchen/IngredientsView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-ingredients"],
                    },
                  },
                  {
                    path: "dishes",
                    name: "dishes-configurations",
                    component: () =>
                      import("../views/configurations/Kitchen/DishesView.vue"),
                    meta: {
                      permissions: ["admin-get-dishes"],
                    },
                  },
                  {
                    path: "menus",
                    name: "menus-configurations",
                    component: () =>
                      import("../views/configurations/Kitchen/MenusView.vue"),
                    meta: {
                      permissions: ["admin-get-menus"],
                    },
                  },
                ],
              },
              {
                path: "cash-box",
                component: () => import("../layouts/EmptyLayout.vue"),
                children: [
                  {
                    path: "boxes",
                    name: "cash-boxes-configurations",
                    component: () =>
                      import(
                        "../views/configurations/CashBox/CashBoxesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-cash_boxes"],
                    },
                  },
                  {
                    path: "source-categories",
                    name: "source-categories-configurations",
                    component: () =>
                      import(
                        "../views/configurations/CashBox/CashBoxSourceCategoriesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-cash_box_source_categories"],
                    },
                  },
                  {
                    path: "sources",
                    name: "sources-configurations",
                    component: () =>
                      import(
                        "../views/configurations/CashBox/CashBoxSourcesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-cash_box_sources"],
                    },
                  },
                ],
              },
              {
                path: "user-sheet",
                component: () => import("../layouts/EmptyLayout.vue"),
                children: [
                  {
                    path: "rates",
                    name: "user-sheet-rates-configurations",
                    component: () =>
                      import("../views/configurations/UserSheet/RatesView.vue"),
                    meta: {
                      permissions: ["admin-get-user_sheet_rates"],
                    },
                  },
                  {
                    path: "shifts",
                    name: "user-sheet-shifts-configurations",
                    component: () =>
                      import(
                        "../views/configurations/UserSheet/ShiftsView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-user_sheet_shifts"],
                    },
                  },
                  {
                    path: "days-in-month",
                    name: "user-sheet-days-configurations",
                    component: () =>
                      import(
                        "../views/configurations/UserSheet/DaysConfigView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-user_sheet_days_config"],
                    },
                  },
                ],
              },
              {
                path: "finance",
                component: () => import("../layouts/EmptyLayout.vue"),
                children: [
                  {
                    path: "room-categories",
                    name: "room-categories-price-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Finance/RoomCategoriesPricesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-room_categories_prices"],
                    },
                  },
                  {
                    path: "services",
                    name: "services-price-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Finance/ServicesPricesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-services_prices"],
                    },
                  },
                  {
                    path: "dishes",
                    name: "dishes-price-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Finance/DishesPricesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-dishes_prices"],
                    },
                  },
                  {
                    path: "payment-type-categories",
                    name: "payment-type-categories-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Finance/PaymentTypeCategoriesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-payment_type_categories"],
                    },
                  },
                  {
                    path: "payment-types",
                    name: "payment-types-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Finance/PaymentTypesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-payment_types"],
                    },
                  },
                  {
                    path: "exchange-rate-groups",
                    name: "exchange-rate-groups-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Finance/ExchangeRateGroupsView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-currency_exchanges"],
                    },
                  },
                ],
              },
              {
                path: "template-types",
                component: () => import("../layouts/EmptyLayout.vue"),
                children: [
                  {
                    path: "",
                    name: "template-types-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Template/TemplatesTypesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-templates"],
                    },
                  },
                  {
                    path: ":type",
                    name: "templates-configurations",
                    component: () =>
                      import(
                        "../views/configurations/Template/TemplatesView.vue"
                      ),
                    meta: {
                      permissions: ["admin-get-template_languages"],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: AuthComponent,
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
      },
      {
        path: "/restore-user-password",
        name: "restore-user-password",
        component: () => import("../views/UserRestorePasswordView.vue"),
      },
      {
        path: "/confirm-restore-user-password",
        name: "confirm-restore-user-password",
        component: () => import("../views/UserConfirmRestorePasswordView.vue"),
      },
    ],
  },
  {
    path: "/logout",
    component: LogoutView,
  },
  {
    path: "/access-denied",
    component: AccessDenied,
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// check redirect role,permission
router.beforeEach(async (to, from, next) => {
  const isLoggedIn = getFromLocalStorage("accessToken");

  if (to.matched.some((m) => m.meta.auth) && !isLoggedIn) {
    return next({
      path: `/login`,
      query: {
        redirectAfter: to.path,
      },
    });
  }

  const tmpUser = getFromLocalStorage("user");
  if (isLoggedIn && tmpUser) {
    if (USER_PRIVILEGES === null) {
      const user = JSON.parse(tmpUser);
      // to clear localstorage and logout
      if (!user.userGroup) {
        localStorage.clear();
        location.reload();
      }
      changeUserPrivileges(user.userGroup.privileges);
    }

    console.log("USER_PRIVILEGES", USER_PRIVILEGES, to.matched);
    const allRoutePermissions = to.matched
      .filter((m) => m.meta.permissions && m.meta.permissions.length)
      .reduce((acc, curr) => {
        acc.push(...curr.meta.permissions);
        return acc;
      }, []);

    if (!allRoutePermissions.every((role) => !!USER_PRIVILEGES[role])) {
      return next({
        path: "/access-denied",
        query: {
          fromPage: from.path,
        },
      });
    }
  }

  // end check router prior with permission, role
  return next();
});

export default router;
