import gql from 'graphql-tag'

export const GET_CASH_BOX_PAGINATED_QUERY = gql`
  query GetCashBoxPaginated (
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
  ) {
    getCashBoxPaginated (
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
      }
    ) {
      hasMore
      total
      items {
        id
        title
      }
    }
  }
`
