import gql from 'graphql-tag'


export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    loginUser(
      data: {
        email: $email
        password: $password
      }
    ) {
      accessToken
      refreshToken
      user {
        id
        firstName
        lastName
        shortFullName
        fullName
        telNumber
        email
        profilePhotoX64 {
          id
          photoSize
          fullUri
        }
        profilePhotoX128 {
          id
          photoSize
          fullUri
        }
        address
        userGroupAlt
        userGroup {
          alt
          privileges {
            alt
          }
        }
      }
    }
  }
`
