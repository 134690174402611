import {i18n} from "@/plugins/i18n";

export class PaymentUtil {
	
	static mapAllCurrencies(currencies) {
		const actualCurrencies = [];
		const mappedCurrencies = {};
		let main = null;
		for (let i = 0; i < currencies.length; i++) {
			const currency = currencies[i];
			mappedCurrencies[currency.title] = currency;
			if (!currency.deletedAt) {
				actualCurrencies.push(currency);
			}
			if (currency.isMain) {
				main = currency;
			}
		}
		return {
			actual: actualCurrencies,
			mapped: mappedCurrencies,
			main
		};
	}
	
	static mapExchangeRates(exchangeGroup) {
		const mapped = {};
		for (let i = 0; i < exchangeGroup.rates.length; i++) {
			const rate = exchangeGroup.rates[i];
			mapped[rate.currencyTitle] = rate;
		}
		exchangeGroup.rates = mapped;
		return exchangeGroup;
	}
	
	static toPaymentTypesAutocompleteList(paymentTypes) {
		const list = [];
		for (let i = 0; i < paymentTypes.length; i++) {
			list.push(paymentTypes[i]);
			if (paymentTypes[i + 1] && paymentTypes[i].category.id !== paymentTypes[i + 1].category.id) {
				list.push(
						{ divider: true },
						{
							header: i18n.te(`payment.type.${paymentTypes[i + 1].category.title}.short`)
									? i18n.t(`payment.type.${paymentTypes[i + 1].category.title}.short`)
									: paymentTypes[i + 1].category.title
						});
			}
		}
		return list;
	}
}
