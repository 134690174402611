import gql from 'graphql-tag'

export const GET_USER_SHIFT_HISTORY_PAGINATION_QUERY = gql`
  query GetUserShiftHistoryPagination (
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
    $userGroupShiftId: Int!
  ) {
    getUserGroupShiftHistoryPaginated (
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
        userGroupShiftId: $userGroupShiftId
      }
    ) {
      hasMore
      total
      items {
        id
        payment
        from
        to
        currencyTitle
        hours
      }
    }
  }
`
