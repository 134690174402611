import gql from 'graphql-tag'

export const GET_SERVICES_PRICES_PAGINATION_QUERY = gql`
  query GetServicesPricesPagination (
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
    $query: String
  ) {
    getServicesPrices (
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
        query: $query
      }
    ) {
      hasMore
      total
      items {
        id
        title
        prices {
          value
        }
      }
    }
  }
`
