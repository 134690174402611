import { handleGqlResponseBody } from "@/utils/apollo.util";
import { toSnakeCase } from "@/utils/string";
import { GET_USERS_PAGINATION_QUERY } from "@/graphql/queries/user/get-users-pagination.query";
const user = {
  state: {
    actualUserGroups: [],
  },
  getters: {
    getActualUserGroups(state) {
      return state.actualUserGroups;
    },
  },
  mutations: {
    setActualUserGroups(state, userGroups) {
      state.actualUserGroups = userGroups;
    },
  },
  actions: {
    async getUsersPaginatedQuery(
      { commit },
      { skip, take, orderBy, isActive, query, userGroupAlt }
    ) {
      orderBy = orderBy.map((o) => ({
        ...o,
        fieldName: toSnakeCase(o.fieldName),
      }));
      return await handleGqlResponseBody(({ query: queryMethod }) => {
        return queryMethod({
          query: GET_USERS_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            isActive,
            query,
            userGroupAlt,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default user;
