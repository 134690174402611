import gql from 'graphql-tag'

export const GET_TODO_ITEMS_PAGINATION_QUERY = gql`
  query GetTodoItemsPagination (
    $closed: Boolean = false
    $supervisorId: Int
    $executorId: Int
    $status: TodoStatus
    $from: DateTimeIso
    $to: DateTimeIso
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
  ) {
    getTodoItemsPaginated (
      data: {
        closed: $closed
        supervisorId: $supervisorId
        executorId: $executorId
        status: $status
        from: $from
        to: $to
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
      }
    ) {
      hasMore
      total
      items {
        id
        supervisor {
          id
          shortFullName
        }
        executor {
          id
          shortFullName
        }
        description
        deadline
        status
        closed
        priority
        createdAt
      }
    }
  }
`
