import gql from 'graphql-tag'

export const GET_CLEANING_CHECK_LIST_ITEMS_PAGINATION_QUERY = gql`
  query GetCleaningCheckListItemsPagination (
    $date: DateString
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
  ) {
    getCleaningCheckListItems (
      data: {
        date: $date
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
      }
    ) {
      hasMore
      total
      items {
        id
        supervisor {
          id
          shortFullName
        }
        cleaner {
          id
          fullName
          shortFullName
        }
        room {
          number
          categories {
            title
          }
        }
        bookingCheckIn
        bookingCheckOut
        guestName
        adultsCount
        childrenCount
        notes
        status
        cleaningStatus
        cleaningType
        createdAt
				date
      }
    }
  }
`
