import gql from "graphql-tag";

export const GET_SERVICES_PAGINATION_QUERY = gql`
  query GetServicesPagination(
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
    $query: String
  ) {
    getServices(
      data: {
        orderBy: $orderBy
        pagination: { skip: $skip, take: $take }
        query: $query
      }
    ) {
      hasMore
      total
      items {
        id
        title
        fromDate
        toDate
        fromTime
        toTime
      }
    }
  }
`;
