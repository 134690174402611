import { handleGqlResponseBody } from "@/utils/apollo.util";
import {GET_EXCHANGE_RATE_GROUPS_QUERY} from '@/graphql/queries/configuration/finance/get-exchange-rate-groups.query';
import {toSnakeCase} from '@/utils/string';

const exchangeRateGroup = {
  actions: {
    async getExchangeRateGroupsQuery({ commit }, { skip, take, orderBy }) {
      orderBy = orderBy.map((o) => ({
        ...o,
        fieldName: toSnakeCase(o.fieldName),
      }));
      return await handleGqlResponseBody(({ query }) => {
        return query({
          query: GET_EXCHANGE_RATE_GROUPS_QUERY,
          variables: {
            skip,
            take,
            orderBy
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default exchangeRateGroup;
