import gql from 'graphql-tag'

export const GET_KITCHEN_MENUS_PAGINATION_QUERY = gql`
  query GetKitchenMenusPagination (
    $skip: Int!,
    $take: Int!,
    $orderBy: [OrderByInput!]
    $query: String
  ) {
    getKitchenMenus (
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
        query: $query
      }
    ) {
      hasMore
      total
      items {
        id
        title
        description
        fromDate
        toDate
        fromTime
        toTime
        dishes {
          value
          dish {
            id
            title
            unit
          }
        }
      }
    }
  }
`
