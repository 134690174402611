import {handleGqlResponseBody} from '@/utils/apollo.util'
import {toSnakeCase} from '@/utils/string';
import {GET_TODO_ITEMS_PAGINATION_QUERY} from '@/graphql/queries/todo/get-todo-items-pagination.query';
import {GET_CLEANING_ITEMS_PAGINATION_QUERY} from '@/graphql/queries/todo/get-cleaning-items-pagination.query';
import {GET_CLEANING_CHECK_LIST_ITEMS_PAGINATION_QUERY} from '@/graphql/queries/todo/get-cleaning-check-list-items-pagination.query';

const todo = {
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
		async getTodoDataPaginatedQuery({commit}, {
			skip, take, orderBy, from, to, closed
		}) {
			// orderBy = orderBy.map((o) => ({
			// 	...o,
			// 	// fieldName: 'todo.' + toSnakeCase(o.fieldName),
			// 	fieldName: 'todo.' + o.fieldName,
			// }));
			return handleGqlResponseBody(apollo => {
				return apollo.query({
					query: GET_TODO_ITEMS_PAGINATION_QUERY,
					variables: {
						skip, take, orderBy, from, to, closed
					},
					fetchPolicy: 'no-cache'
				})
			});
		},
		async getCleaningDataPaginatedQuery({commit}, {
			skip, take, orderBy, date
		}) {
			// orderBy = orderBy.map((o) => ({
			// 	...o,
			// 	// fieldName: 'todo.' + toSnakeCase(o.fieldName),
			// 	fieldName: 'todo.' + o.fieldName,
			// }));
			
			return handleGqlResponseBody(apollo => {
				return apollo.query({
					query: GET_CLEANING_ITEMS_PAGINATION_QUERY,
					variables: {
						skip, take, orderBy, date
					},
					fetchPolicy: 'no-cache'
				})
			});
		},
		async getCleaningCheckListDataPaginatedQuery({commit}, {
			skip, take, orderBy, date
		}) {
			// orderBy = orderBy.map((o) => ({
			// 	...o,
			// 	// fieldName: 'todo.' + toSnakeCase(o.fieldName),
			// 	fieldName: 'todo.' + o.fieldName,
			// }));
			
			return handleGqlResponseBody(apollo => {
				return apollo.query({
					query: GET_CLEANING_CHECK_LIST_ITEMS_PAGINATION_QUERY,
					variables: {
						skip, take, orderBy, date
					},
					fetchPolicy: 'no-cache'
				})
			});
		},
	}
}

export default todo
