import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_ROOM_CATEGORIES_PRICES_PAGINATION_QUERY } from "@/graphql/queries/configuration/finance/get-room-categories-prices-pagination.query";
import { GET_SERVICES_PRICES_PAGINATION_QUERY } from "@/graphql/queries/configuration/finance/get-services-prices-pagination.query";
import { GET_DISHES_PRICES_PAGINATION_QUERY } from "@/graphql/queries/configuration/finance/get-dishes-prices-pagination.query";

const roomPrice = {
  actions: {
    async getRoomCategoriesPricesQuery(
      { commit },
      { skip, take, orderBy, query }
    ) {
      return await handleGqlResponseBody(({ query: queryMethod }) => {
        return queryMethod({
          query: GET_ROOM_CATEGORIES_PRICES_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getServicesPricesQuery({ commit }, { skip, take, orderBy, query }) {
      return await handleGqlResponseBody(({ query: queryMethod }) => {
        return queryMethod({
          query: GET_SERVICES_PRICES_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getDishesPricesQuery({ commit }, { skip, take, orderBy, query }) {
      return await handleGqlResponseBody(({ query: queryMethod }) => {
        return queryMethod({
          query: GET_DISHES_PRICES_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default roomPrice;
