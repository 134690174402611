export const TOP_BAR_TAB_ITEMS = [
	{
		title: 'checkerboard',
		icon: 'mdi-view-comfy',
		to: '/checkerboard',
		privileges: ['checkerboard-get']
	},
	{
		title: 'cashbox',
		icon: 'mdi-cash-register',
		to: '/cashbox',
		privileges: ['cashbox-get']
	},
	{
		title: 'userSheet',
		icon: 'mdi-account-clock-outline',
		to: '/user-sheet',
		privileges: ['usersheet-get-table_data']
	},
	{
		title: 'todo',
		icon: 'mdi-notebook-check-outline',
		to: '/todo',
		privileges: ['todo-get']
	},
	{
		title: 'clients',
		icon: 'mdi-account-multiple-outline',
		to: '/clients',
		privileges: ['client-get']
	},
	{
		title: 'configuration',
		icon: 'mdi-account-cog-outline',
		to: '/configuration',
		// privileges: [],
		privileges: ['admin-get'],
	}
];
