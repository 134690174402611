import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_KITCHEN_MENUS_PAGINATION_QUERY } from "@/graphql/queries/configuration/kitchenMenu/get-kitchen-menus-pagination.query";

const kitchenMenu = {
  actions: {
    async getKitchenMenusQuery({ commit }, { skip, take, orderBy, query }) {
      return await handleGqlResponseBody(({ query: queryMethod }) => {
        return queryMethod({
          query: GET_KITCHEN_MENUS_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default kitchenMenu;
