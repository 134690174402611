import gql from 'graphql-tag'

export const CHECK_RESET_USER_PASSWORD_TOKEN_MUTATION = gql`
  mutation CheckResetUserPasswordTokenMutation ($token: String!, $code: String!){
    checkResetUserPasswordToken(
      data: {
        token: $token
        code: $code
      }
    ) {
      email
    }
  }
`
