import {USER_PRIVILEGES} from '@/store/modules/authentication';

export const privilegeMixin = {
  methods: {
    hasPrivilege(privilege) {
      return !!USER_PRIVILEGES[privilege]
    },
    haveOneOfPrivileges(privileges) {
      if (privileges.length === 0) return true;
      return privileges.some(privilege => this.hasPrivilege(privilege))
    }
  },
};
